import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SidebarPaneHeader({
  header,
  onClose,
}: {
  header: string;
  onClose: VoidFunction;
}) {
  return (
    <Stack direction="row">
      <Typography
        variant="h6"
        color="text.secondary"
        component="h3"
        flexGrow={1}
      >
        {header}
      </Typography>
      <Box>
        <IconButton
          aria-label="close"
          size="small"
          title="Close"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Stack>
  );
}
