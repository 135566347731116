import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { usePublishVersion_Mutation$variables } from "./__generated__/usePublishVersion_Mutation.graphql";

export const usePublishVersion = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation usePublishVersion_Mutation(
      $id: ID!
      $content: String
      $notes: String
    ) {
      publishVersion(input: { id: $id, content: $content, notes: $notes }) {
        version {
          id
          content
          draft
          html
          notes
          lastActivityOn
        }
      }
    }
  `);

  const func = (
    variables: usePublishVersion_Mutation$variables,
    onCompleted?: (response: {}) => void
  ) => {
    mutation({
      variables,
      onCompleted,
    });
  };

  return [func, inFlight] as [
    (
      variables: usePublishVersion_Mutation$variables,
      onCompleted?: (response: {}) => void
    ) => void,
    boolean
  ];
};
