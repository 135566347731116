import { useState, createContext, ReactNode } from "react";

type leftSidebarKeys = "Table of Contents" | "Documents" | "Search" | "None";
type rightSidebarKeys = "Annotations" | "Versions" | "Format" | "None";

export type sidebarContextType = {
  leftSidebarPane: leftSidebarKeys;
  setLeftSidebarPane: (key: leftSidebarKeys) => void;
  rightSidebarPane: rightSidebarKeys;
  setRightSidebarPane: (key: rightSidebarKeys) => void;
};

const notImplemented = () => {
  throw new Error("Not Implemented");
};

export const sidebarContext = createContext<sidebarContextType>({
  leftSidebarPane: "Documents",
  setLeftSidebarPane: notImplemented,
  rightSidebarPane: "Annotations",
  setRightSidebarPane: notImplemented,
});

export default function SidebarContext({ children }: { children: ReactNode }) {
  const stateKey = "amethyst.sidebars";
  type stateType = {
    leftSidebarPane?: leftSidebarKeys;
    rightSidebarPane?: rightSidebarKeys;
  };

  const defaultState: Required<stateType> = {
    leftSidebarPane: "Documents",
    rightSidebarPane: "Annotations",
  };
  const state = JSON.parse(
    window.localStorage.getItem(stateKey) || "{}"
  ) as Partial<stateType>;

  const setState = (state: stateType) => {
    window.localStorage.setItem(stateKey, JSON.stringify(state));
  };

  const [leftSidebarPane, internalSetLeftSidebarPane] =
    useState<leftSidebarKeys>(
      state.leftSidebarPane ?? defaultState.leftSidebarPane
    );

  const [rightSidebarPane, internalSetRightSidebarPane] =
    useState<rightSidebarKeys>(
      state.rightSidebarPane ?? defaultState.rightSidebarPane
    );

  const setLeftSidebarPane = (key: leftSidebarKeys) => {
    internalSetLeftSidebarPane(key);
    setState({
      ...defaultState,
      ...state,
      leftSidebarPane: key,
    });
  };

  const setRightSidebarPane = (key: rightSidebarKeys) => {
    internalSetRightSidebarPane(key);
    setState({
      ...defaultState,
      ...state,
      rightSidebarPane: key,
    });
  };

  return (
    <sidebarContext.Provider
      value={{
        leftSidebarPane,
        setLeftSidebarPane,
        rightSidebarPane,
        setRightSidebarPane,
      }}
    >
      {children}
    </sidebarContext.Provider>
  );
}
