import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import PublishDialogForm from "./PublishDialogForm";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { DocumentEditor_Query$key } from "./__generated__/DocumentEditor_Query.graphql";
import { useUpdateVersion } from "../hooks/versions/useUpdateVersion";
import { usePublishVersion } from "../hooks/versions/usePublishVersion";
import MarkdownRender from "../MarkdownViewer/MarkdownRender";

interface Props {
  query: DocumentEditor_Query$key;
  type?: "MARKDOWN" | "MERMAID";
  setView: VoidFunction;
}
export default function DocumentEditor({ query, type, setView }: Props) {
  const data = useFragment(
    graphql`
      fragment DocumentEditor_Query on ContentVersion {
        id
        content
        html
      }
    `,
    query
  );

  const [editorContent, setEditorContent] = useState(data.content ?? "");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);

  useEffect(() => {
    setEditorContent(data.content ?? "");
  }, [data]);

  const [updateVersion, updating] = useUpdateVersion();
  const [publishVersion, publishing] = usePublishVersion();

  const onChange = (value: string) => {
    setEditorContent(value);
  };

  const save = async () => {
    updateVersion({
      id: data.id,
      content: editorContent,
    });
  };

  const preview = async () => {
    updateVersion(
      {
        id: data.id,
        content: editorContent,
      },
      () => {
        setPreviewOpen(true);
      }
    );
  };

  const publish = async (notes?: string) => {
    publishVersion({
      id: data.id,
      content: editorContent,
      notes,
    });

    setView();
  };

  return (
    <Stack direction="row" gap={2} className="DocumentEditor" flexGrow={1}>
      <PublishDialogForm
        open={publishDialogOpen}
        onCancel={() => setPublishDialogOpen(false)}
        onSave={publish}
      />
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        aria-labelledby="preview-dialog-title"
      >
        <DialogTitle id="preview-dialog-title">Preview</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <MarkdownRender html={data.html} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <AceEditor
        placeholder="Documents..."
        mode={type === "MARKDOWN" ? "markdown" : "text"}
        theme="github"
        name="editor"
        fontSize={14}
        showPrintMargin={false}
        showGutter={true}
        highlightActiveLine={true}
        value={editorContent}
        onChange={onChange}
        width="100%"
        height="100%"
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      <Divider flexItem variant="middle" orientation="vertical" />
      <Stack gap={2}>
        <IconButton
          title="Save"
          onClick={save}
          disabled={updating}
          sx={{ border: "1px solid" }}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          title="Preview"
          onClick={preview}
          disabled={updating}
          sx={{ border: "1px solid" }}
        >
          <VisibilityIcon />
        </IconButton>
        <IconButton
          title="Publish"
          onClick={() => setPublishDialogOpen(true)}
          disabled={publishing}
          sx={{ border: "1px solid" }}
        >
          <PublishIcon />
        </IconButton>
        <IconButton
          title="Close"
          onClick={setView}
          sx={{ border: "1px solid" }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
