import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useUpdateProject_Mutation$variables } from "./__generated__/useUpdateProject_Mutation.graphql";

export const useUpdateProject = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useUpdateProject_Mutation(
      $id: ID!
      $name: String!
      $description: String!
    ) {
      updateProject(
        input: { id: $id, name: $name, description: $description }
      ) {
        project {
          id
          name
          description
          archived
          lastActivityOn
        }
      }
    }
  `);

  const func = (
    variables: useUpdateProject_Mutation$variables,
    onCompleted?: (response: {}) => void
  ) => {
    mutation({
      variables,
      onCompleted,
    });
  };

  return [func, inFlight] as [
    (
      variables: useUpdateProject_Mutation$variables,
      onCompleted?: (response: {}) => void
    ) => void,
    boolean
  ];
};
