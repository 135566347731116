import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

export default function PublishDialogForm({
  open,
  onCancel,
  onSave,
}: {
  open: boolean;
  onCancel: VoidFunction;
  onSave: (notes?: string) => void;
}) {
  const [notes, setNotes] = useState<string>();

  const handleNotesChange = (value: string) => {
    setNotes(value);
  };

  const cancel = () => {
    onCancel();
    setNotes(undefined);
  };

  const publish = () => {
    onSave(notes);
    setNotes(undefined);
  };

  return (
    <Dialog open={open} onClose={cancel} aria-labelledby="publish-dialog-title">
      <DialogTitle id="publish-dialog-title">Publish</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          multiline
          required
          label="Notes"
          variant="standard"
          value={notes}
          onChange={(ev) => handleNotesChange(ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={publish} variant="contained">
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
}
