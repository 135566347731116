/**
 * @generated SignedSource<<ac120fe11080624d5655f86336c7d405>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MermaidViewer_Query$data = {
  readonly content: string | null | undefined;
  readonly " $fragmentType": "MermaidViewer_Query";
};
export type MermaidViewer_Query$key = {
  readonly " $data"?: MermaidViewer_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"MermaidViewer_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MermaidViewer_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "type": "ContentVersion",
  "abstractKey": null
};

(node as any).hash = "da2a27d02bf60803985a05fc7e45bb21";

export default node;
