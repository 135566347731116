import { useContext, useLayoutEffect, useState } from "react";
import { sidebarContext } from "../../Contexts/SidebarContext";
import { Box, IconButton, List, Stack, useTheme } from "@mui/material";
import SidebarPaneHeader from "../../Sidebars/SidebarPaneHeader";
import Annotation from "./Annotation";
import { orderBy } from "lodash";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Annotations_Query$key } from "./__generated__/Annotations_Query.graphql";
import AddIcon from "@mui/icons-material/Add";
import AnnotationDialogForm from "./AnnotationDialogForm";
import { useCreateAnnotation } from "../../hooks/annotation/useCreateAnnotation";
import { useSearchParams } from "react-router-dom";

interface Props {
  readOnly: boolean;
  query?: Annotations_Query$key | null;
  activeVersionId?: string | null;
}
export default function Annotations({
  readOnly,
  query,
  activeVersionId,
}: Props) {
  const [params] = useSearchParams();
  const annotationIdParam = params.get("annotationId");

  const data = useFragment(
    graphql`
      fragment Annotations_Query on AnnotationsConnection {
        __id
        edges {
          node {
            id
            lastActivityOn
            replyTo
            ...Annotation_Query
          }
        }
      }
    `,
    query
  );

  const { rightSidebarPane, setRightSidebarPane } = useContext(sidebarContext);
  const [newAnnotationDialogOpen, setNewAnnotationDialogOpen] = useState(false);

  const [createAnnotation] = useCreateAnnotation();

  const createAnnotationAction = () => {
    setNewAnnotationDialogOpen(true);
  };

  const saveNewAnnotation = (content: string) => {
    if (activeVersionId) {
      createAnnotation({
        versionId: activeVersionId,
        content,
        connections: data?.__id ? [data.__id] : [],
      });
    }

    setNewAnnotationDialogOpen(false);
  };

  const cancelNewAnnotation = () => {
    setNewAnnotationDialogOpen(false);
  };

  useLayoutEffect(() => {
    if (annotationIdParam) {
      const el = document.getElementById(annotationIdParam);
      el?.scrollIntoView(true);
    }
  });

  return (
    <>
      {rightSidebarPane === "Annotations" && (
        <Stack
          useFlexGap
          gap={1}
          className="TableOfContents"
          sx={{ minWidth: "300px", maxWidth: "500px", height: "100%" }}
        >
          <SidebarPaneHeader
            header="Annotations"
            onClose={() => setRightSidebarPane("None")}
          />
          <List
            disablePadding
            sx={{ flexGrow: 1, overflow: "auto", paddingRight: 1 }}
          >
            {orderBy(
              data?.edges
                ?.filter((f) => !!f)
                .map((m) => m.node)
                ?.filter((f) => !!f),
              [(s) => s.lastActivityOn],
              ["desc"]
            )
              .filter((f) => !f.replyTo)
              .map((m) => (
                <Annotation key={m.id} query={m} readOnly={readOnly} />
              ))}
          </List>
          {activeVersionId && !readOnly && (
            <Box sx={{ alignSelf: "center" }}>
              <IconButton
                title="New Annotation"
                sx={{ alignSelf: "baseline" }}
                onClick={createAnnotationAction}
              >
                <AddIcon />
              </IconButton>
              <AnnotationDialogForm
                open={newAnnotationDialogOpen}
                onSave={saveNewAnnotation}
                onCancel={cancelNewAnnotation}
              />
            </Box>
          )}
        </Stack>
      )}
    </>
  );
}
