import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import { Resolver } from "found-relay";
// import environment from "./relay-env";
import Router from "./Router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// note: React.StrictMode renders components twice in dev
// this causes `useEffect` to run twice, calling the API twice
root.render(
  <React.StrictMode>
    <Router />
    {/* <Router resolver={new Resolver(environment)} /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
