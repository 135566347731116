export default function RenderWithLineBreaks({ text }: { text?: string }) {
  if (!text) {
    return <span></span>;
  }

  const render = text
    .split(/\n/)
    .flatMap((m, i) => [
      <span key={i + "-span"}>{m}</span>,
      <br key={i + "-br"} />,
    ])
    .slice(0, -1);

  return <>{render}</>;
}
