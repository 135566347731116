import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import SidebarPaneHeader from "../../Sidebars/SidebarPaneHeader";
import DocumentDialogForm from "./DocumentDialogForm";
import { useContext, useState } from "react";
import { confirmationDialogContext } from "../../../ConfirmationDialogContext";
import { orderBy } from "lodash";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import { DateTime } from "luxon";
import { sidebarContext } from "../../Contexts/SidebarContext";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  DocumentType,
  DocumentList_Query$data,
  DocumentList_Query$key,
} from "./__generated__/DocumentList_Query.graphql";
import { useCreateDocument } from "../../hooks/document/useCreateDocument";
import { useFavoriteDocument } from "../../hooks/document/useFavoriteDocument";
import { useUnfavoriteDocument } from "../../hooks/document/useUnfavoriteDocument";
import { useDeleteDocument } from "../../hooks/document/useDeleteDocument";
import { useUpdateDocument } from "../../hooks/document/useUpdateDocument";
import { Requires } from "../../../UserContext";

type documentType = NonNullable<
  NonNullable<DocumentList_Query$data["documents"]>["edges"]
>[number]["node"];

interface Props {
  query?: DocumentList_Query$key | null;
  readOnly: boolean;
  activeDocumentId?: string | null;
}
export default function DocumentList({
  query,
  readOnly,
  activeDocumentId,
}: Props) {
  const data = useFragment(
    graphql`
      fragment DocumentList_Query on Project {
        id
        friendlyId
        documents {
          __id
          edges {
            node {
              id
              friendlyId
              favorite
              lastActivityOn
              name
              type
              externalLink
              ...DocumentDialogForm_Query
            }
          }
        }
      }
    `,
    query
  );

  const navigate = useNavigate();

  const { confirm } = useContext(confirmationDialogContext);

  const [createDocument] = useCreateDocument();
  const [updateDocument] = useUpdateDocument();
  const [favoriteDocument] = useFavoriteDocument();
  const [unfavoriteDocument] = useUnfavoriteDocument();
  const [deleteDocument] = useDeleteDocument();

  const { leftSidebarPane, setLeftSidebarPane } = useContext(sidebarContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [documentWithMenu, setDocumentWithMenu] = useState<documentType>();
  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    document: documentType
  ) => {
    setAnchorEl(event.currentTarget);
    setDocumentWithMenu(document);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setDocumentWithMenu(undefined);
  };

  const [newDocumentDialogOpen, setNewDocumentDialogOpen] = useState(false);

  const [editDocumentDialogOpen, setEditDocumentDialogOpen] = useState(false);

  const saveNewDocument = (document: {
    name: string;
    type: DocumentType;
    externalLink: string;
  }) => {
    if (data?.id) {
      createDocument({
        projectId: data.id,
        ...document,
        connections: data.documents ? [data.documents.__id] : [],
      });
      setNewDocumentDialogOpen(false);
    }
  };

  const saveDocument = (req: any) => {
    if (documentWithMenu) {
      updateDocument({ ...documentWithMenu, ...req });
      setEditDocumentDialogOpen(false);
    }

    closeMenu();
  };

  const cancelNewDocument = () => {
    setNewDocumentDialogOpen(false);
    closeMenu();
  };

  const cancelEditDocument = () => {
    setEditDocumentDialogOpen(false);
    closeMenu();
  };

  const toggleFavorite = (req: documentType) => {
    if (req.favorite) {
      unfavoriteDocument(req.id);
    } else {
      favoriteDocument(req.id);
    }
  };

  const confirmDeleteDocument = () => {
    if (documentWithMenu) {
      confirm(
        "Confirm Delete",
        `Confirm you want to delete document '${documentWithMenu.name}'.`,
        "Delete",
        () => () => {
          deleteDocument(documentWithMenu.id);
          closeMenu();
        }
      );
    }
  };

  const onOpenDocument = (req: documentType) => {
    if (data && req.type !== "LINK") {
      navigate(`/${data.friendlyId}/doc/${req.friendlyId}`);
      return;
    } else {
      confirm(
        "External Document",
        `Confirm you want to go to '${req.externalLink}'.`,
        "Confirm",
        () => () => {
          window.open(req.externalLink!, "_blank");
          closeMenu();
        }
      );
    }
  };

  return (
    <>
      {leftSidebarPane === "Documents" && (
        <Stack className="DocumentList" sx={{ minWidth: "200px", height: "100%" }}>
          <Menu
            id="documents-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={closeMenu}
            MenuListProps={{
              "aria-label": "actions",
            }}
          >
            <Requires requires="WRITE">
              <MenuItem
                onClick={() => setEditDocumentDialogOpen(true)}
                disabled={readOnly}
              >
                Edit
              </MenuItem>
            </Requires>
            <Requires requires="ADMIN">
              <MenuItem onClick={confirmDeleteDocument} disabled={readOnly}>
                Delete
              </MenuItem>
            </Requires>
          </Menu>

          <DocumentDialogForm
            query={documentWithMenu}
            title="Edit Document"
            open={editDocumentDialogOpen}
            onSave={saveDocument}
            onCancel={cancelEditDocument}
          />

          <SidebarPaneHeader
            header="Documents"
            onClose={() => setLeftSidebarPane("None")}
          />
          <nav style={{ flexGrow: 1, overflow: "auto" }}>
            <List>
              {orderBy(
                data?.documents?.edges
                  ?.filter((f) => !!f)
                  .map((m) => m.node)
                  ?.filter((f) => !!f),
                [
                  (f) => f.favorite,
                  (f) => DateTime.fromISO(f.lastActivityOn).toSeconds(),
                ],
                ["desc", "desc"]
              ).map((m) => (
                <ListItem
                  key={m.id}
                  disablePadding
                  disableGutters
                  secondaryAction={
                    <>
                      <IconButton
                        onClick={() => toggleFavorite(m)}
                        aria-label="actions"
                        aria-controls={anchorEl ? "documents-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? "true" : undefined}
                      >
                        {m.favorite && <StarIcon />}
                        {!m.favorite && <StarOutlineIcon />}
                      </IconButton>
                      <Requires requires="WRITE">
                        <IconButton
                          onClick={(ev) => openMenu(ev, m)}
                          aria-label="actions"
                          aria-controls={
                            anchorEl ? "documents-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={anchorEl ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Requires>
                    </>
                  }
                >
                  <ListItemButton
                    selected={m.id === activeDocumentId}
                    onClick={() => onOpenDocument(m)}
                  >
                    <ListItemText primary={m.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </nav>
          {!readOnly && (
            <Requires requires="WRITE">
              <Box sx={{ alignSelf: "center" }}>
                <IconButton
                  title="New Document"
                  sx={{ alignSelf: "baseline" }}
                  onClick={() =>
                    setNewDocumentDialogOpen(!newDocumentDialogOpen)
                  }
                >
                  <AddIcon />
                </IconButton>
                <DocumentDialogForm
                  title="Create Document"
                  open={newDocumentDialogOpen}
                  onSave={saveNewDocument}
                  onCancel={cancelNewDocument}
                />
              </Box>
            </Requires>
          )}
        </Stack>
      )}
    </>
  );
}
