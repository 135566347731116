import { useContext, useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import SidebarPaneHeader from "../../Sidebars/SidebarPaneHeader";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { sidebarContext } from "../../Contexts/SidebarContext";
import { useNavigate, useParams } from "react-router-dom";
import { confirmationDialogContext } from "../../../ConfirmationDialogContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DiffDialog from "./DiffDialog";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useDeleteVersion } from "../../hooks/versions/useDeleteVersion";
import {
  VersionList_Query$data,
  VersionList_Query$key,
} from "./__generated__/VersionList_Query.graphql";
import { Requires } from "../../../UserContext";

type contentVersionType = NonNullable<
  NonNullable<VersionList_Query$data["versions"]>["edges"]
>[number]["node"];

interface Props {
  query?: VersionList_Query$key | null;
  readOnly: boolean;
  activeVersionId?: string | null;
}
export default function VersionList({
  query,
  readOnly,
  activeVersionId,
}: Props) {
  const data = useFragment(
    graphql`
      fragment VersionList_Query on Document {
        friendlyId
        versions {
          ...DiffDialog_Query
          edges {
            __id
            node {
              id
              friendlyId
              lastActivityOn
              draft
              notes
            }
          }
        }
      }
    `,
    query
  );

  const { projectId } = useParams();
  const navigate = useNavigate();

  const { confirm } = useContext(confirmationDialogContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [versionWithMenu, setVersionWithMenu] =
    useState<null | contentVersionType>(null);
  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    version: contentVersionType
  ) => {
    setAnchorEl(event.currentTarget);
    setVersionWithMenu(version);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setVersionWithMenu(null);
  };

  const { rightSidebarPane, setRightSidebarPane } = useContext(sidebarContext);

  const [deleteVersion] = useDeleteVersion();

  const [diffDialogOpen, setDiffDialogOpen] = useState(false);

  const confirmDeleteVersion = () => {
    if (data && versionWithMenu) {
      confirm(
        "Confirm Delete",
        `Confirm you want to delete version '${DateTime.fromISO(
          versionWithMenu.lastActivityOn
        ).toLocaleString(DateTime.DATE_FULL)}'.`,
        "Delete",
        () => () => {
          deleteVersion(versionWithMenu.id, () => {
            closeMenu();
            if (activeVersionId === versionWithMenu.id) {
              navigate(`/${projectId}/doc/${data.friendlyId}`);
            }
          });
        }
      );
    }
  };

  const goToDiff = (id: string) => {
    if (data?.friendlyId && versionWithMenu) {
      navigate(
        `/${projectId}/doc/${data.friendlyId}/${versionWithMenu.friendlyId}/diff/${id}`
      );
      closeMenu();
    }
  };

  return (
    <>
      <DiffDialog
        query={data?.versions}
        activeVersionId={versionWithMenu?.id}
        open={diffDialogOpen}
        onCancel={() => setDiffDialogOpen(false)}
        onChoose={goToDiff}
      />
      {rightSidebarPane === "Versions" && (
        <Stack className="VersionList" sx={{ minWidth: "200px", height: "100%" }}>
          <Menu
            id="versions-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={closeMenu}
            MenuListProps={{
              "aria-label": "actions",
            }}
          >
            {
              <MenuItem onClick={() => setDiffDialogOpen(true)}>
                Compare...
              </MenuItem>
            }
            <Requires requires="ADMIN">
              <MenuItem
                onClick={confirmDeleteVersion}
                disabled={readOnly || !versionWithMenu?.draft}
              >
                Delete
              </MenuItem>
            </Requires>
          </Menu>
          <SidebarPaneHeader
            header="Versions"
            onClose={() => setRightSidebarPane("None")}
          />
          <nav style={{ flexGrow: 1, overflow: "auto" }}>
            <List>
              {data?.versions?.edges &&
                orderBy(
                  data.versions.edges
                    .filter((f) => !!f)
                    .map((m) => m.node)
                    .filter((f) => !!f),
                  [(s) => s.lastActivityOn],
                  ["desc"]
                ).map((m) => (
                  <ListItem
                    key={m.id}
                    disablePadding
                    disableGutters
                    secondaryAction={
                      <IconButton
                        onClick={(ev) => openMenu(ev, m)}
                        aria-label="actions"
                        aria-controls={anchorEl ? "documents-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? "true" : undefined}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      selected={m.id === activeVersionId}
                      onClick={() => {
                        navigate(
                          `/${projectId}/doc/${data.friendlyId}/${m.friendlyId}`
                        );
                      }}
                    >
                      <ListItemText
                        primary={DateTime.fromISO(
                          m.lastActivityOn
                        ).toLocaleString(DateTime.DATE_FULL)}
                        secondary={m.draft ? "(draft)" : m.notes}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </nav>
        </Stack>
      )}
    </>
  );
}
