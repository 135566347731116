import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useUpdateUser_Mutation$variables } from "./__generated__/useUpdateUser_Mutation.graphql";

export const useUpdateUser = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useUpdateUser_Mutation(
      $id: ID!
      $name: String!
      $email: String!
      $picture: String
    ) {
      updateProfile(
        input: { id: $id, name: $name, email: $email, picture: $picture }
      ) {
        user {
          id
          externalId
          name
          email
          picture
        }
      }
    }
  `);

  const func = (variables: useUpdateUser_Mutation$variables) => {
    mutation({
      variables,
    });
  };

  return [func, inFlight] as [
    (variables: useUpdateUser_Mutation$variables) => void,
    boolean
  ];
};
