/**
 * @generated SignedSource<<93ffc8da30d2c772d3ea437efb0fa279>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Annotation_Query$data = {
  readonly createdBy: {
    readonly name: string;
    readonly picture: string | null | undefined;
  };
  readonly friendlyId: string;
  readonly id: string;
  readonly replies: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly friendlyId: string;
        readonly id: string;
        readonly lastActivityOn: any;
        readonly " $fragmentSpreads": FragmentRefs<"AnnotationReply_Query">;
      };
    }> | null | undefined;
  } | null | undefined;
  readonly replyTo: string | null | undefined;
  readonly versionId: string;
  readonly " $fragmentSpreads": FragmentRefs<"AnnotationContent_Query">;
  readonly " $fragmentType": "Annotation_Query";
};
export type Annotation_Query$key = {
  readonly " $data"?: Annotation_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"Annotation_Query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Annotation_Query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AnnotationContent_Query"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replyTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepliesConnection",
      "kind": "LinkedField",
      "name": "replies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RepliesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Annotation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastActivityOn",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AnnotationReply_Query"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Annotation",
  "abstractKey": null
};
})();

(node as any).hash = "08bb711a618fb16a453057e8a2020f48";

export default node;
