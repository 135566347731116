/**
 * @generated SignedSource<<44b3b2e327df58be1f060ed5670a0765>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentHeader_Query$data = {
  readonly description: string;
  readonly name: string;
  readonly " $fragmentType": "DocumentHeader_Query";
};
export type DocumentHeader_Query$key = {
  readonly " $data"?: DocumentHeader_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentHeader_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentHeader_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "c1bf6ba819d9ad09a3c939a008df231c";

export default node;
