/**
 * @generated SignedSource<<79683e9214ba98d1aef6216fa6485637>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useCreateVersion_Mutation$variables = {
  basedOn?: string | null | undefined;
  connections: ReadonlyArray<string>;
  documentId: string;
};
export type useCreateVersion_Mutation$data = {
  readonly createVersion: {
    readonly version: {
      readonly content: string | null | undefined;
      readonly documentId: string;
      readonly draft: boolean;
      readonly friendlyId: string;
      readonly html: string;
      readonly id: string;
      readonly lastActivityOn: any;
      readonly notes: string | null | undefined;
    };
  };
};
export type useCreateVersion_Mutation = {
  response: useCreateVersion_Mutation$data;
  variables: useCreateVersion_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "basedOn"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentId"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "basedOn",
        "variableName": "basedOn"
      },
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentVersion",
  "kind": "LinkedField",
  "name": "version",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "html",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastActivityOn",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateVersion_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CreateContentVersionPayload",
        "kind": "LinkedField",
        "name": "createVersion",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCreateVersion_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CreateContentVersionPayload",
        "kind": "LinkedField",
        "name": "createVersion",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "version",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "VersionsEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "024f9b8e1bd83bd43ccbcdf317b93428",
    "id": null,
    "metadata": {},
    "name": "useCreateVersion_Mutation",
    "operationKind": "mutation",
    "text": "mutation useCreateVersion_Mutation(\n  $documentId: ID!\n  $basedOn: ID\n) {\n  createVersion(input: {documentId: $documentId, basedOn: $basedOn}) {\n    version {\n      id\n      friendlyId\n      content\n      draft\n      html\n      notes\n      documentId\n      lastActivityOn\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "370009aee0b179be64d855a30f0be470";

export default node;
