/**
 * @generated SignedSource<<4bc25001f13bace87b6b4c00e85113ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "FIGMA" | "LINK" | "MARKDOWN" | "MERMAID" | "PDF" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentList_Query$data = {
  readonly documents: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly externalLink: string | null | undefined;
        readonly favorite: boolean;
        readonly friendlyId: string;
        readonly id: string;
        readonly lastActivityOn: any;
        readonly name: string;
        readonly type: DocumentType;
        readonly " $fragmentSpreads": FragmentRefs<"DocumentDialogForm_Query">;
      };
    }> | null | undefined;
  } | null | undefined;
  readonly friendlyId: string;
  readonly id: string;
  readonly " $fragmentType": "DocumentList_Query";
};
export type DocumentList_Query$key = {
  readonly " $data"?: DocumentList_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentList_Query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentList_Query",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentsConnection",
      "kind": "LinkedField",
      "name": "documents",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DocumentsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Document",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "favorite",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastActivityOn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalLink",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DocumentDialogForm_Query"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "70459690ea6d33bc9d28ca90eb4f0e37";

export default node;
