import {
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";

export default function HomeRoot() {
  const features = [
    {
      media: "Markdown.png",
      title: "Markdown Documentation",
      bullets: [
        "GitHub-flavor Markdown",
        "Table of Contents based on headers",
        "Versioned by design",
      ],
    },
    {
      media: "Mermaid.png",
      title: "Mermaid Documentation",
      bullets: [
        "Mermaid, for all your diagramming needs",
        "Flow charts, class & relationship diagrams, Gantt & pie charts",
        "Versioned by design",
      ],
    },
    {
      media: "Annotations.png",
      title: "Annotations",
      bullets: [
        "Facilitate discussions",
        "Provide context",
        "Request clarification",
      ],
    },
  ];

  const highlights = [
    {
      media: "Figma.png",
      title: "Figma Embeds",
    },
    {
      media: "PDF.png",
      title: "PDF Embeds",
    },
    {
      media: "ExternalLink.png",
      title: "External Links",
    },
    {
      media: "Compare.png",
      title: "Compare Versions",
    },
  ];

  return (
    <Stack
      className="HomeRoot"
      gap={2}
      sx={{ height: "100%", overflow: "auto" }}
      bgcolor="grey.50"
      px={2}
    >
      <Stack direction={{ xs: "column", sm: "row" }} gap={2} mt={2}>
        {features.map((m, i) => (
          <Card
            key={i}
            sx={{
              width: {
                sm: "33%",
              },
            }}
          >
            <CardContent
              sx={{
                ["&:last-child"]: {
                  paddingBottom: 2,
                },
              }}
            >
              <CardMedia
                sx={{
                  height: "120px",
                  margin: -2,
                  marginBottom: 2,
                  backgroundSize: "contain",
                  borderBottom: "1px solid",
                  borderColor: "divider",
                }}
                image={m.media}
              />
              <Typography variant="h5" component="h3" color="text.secondary">
                {m.title}
              </Typography>
              <List>
                {m.bullets.map((m) => (
                  <ListItem sx={{ padding: "4px 0" }}>{m}</ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Grid container spacing={2} columns={{ xs: 2, sm: 4 }}>
        {highlights.map((m, i) => (
          <Grid key={i} xs={1}>
            <Card>
              <CardContent
                sx={{
                  ["&:last-child"]: {
                    paddingBottom: 2,
                  },
                }}
              >
                <CardMedia
                  sx={{
                    height: "120px",
                    margin: -2,
                    marginBottom: 2,
                    backgroundSize: "contain",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                  image={m.media}
                />
                <Typography variant="h5" component="h3" color="text.secondary">
                  {m.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
