import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { DiffDialog_Query$key } from "./__generated__/DiffDialog_Query.graphql";

interface Props {
  query?: DiffDialog_Query$key | null;
  activeVersionId?: string | null;
  open: boolean;
  onCancel: VoidFunction;
  onChoose: (id: string) => void;
}
export default function DiffDialog({
  query,
  activeVersionId,
  open,
  onCancel,
  onChoose,
}: Props) {
  const data = useFragment(
    graphql`
      fragment DiffDialog_Query on VersionsConnection {
        edges {
          node {
            id
            friendlyId
            draft
            lastActivityOn
          }
        }
      }
    `,
    query
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onCancel}
      aria-labelledby="diff-dialog-title"
    >
      <DialogTitle id="diff-dialog-title">Compare with...</DialogTitle>
      <DialogContent>
        <List component="nav">
          {orderBy(
            data?.edges
              ?.filter((f) => !!f)
              .map((m) => m.node)
              .filter((f) => !!f && f.id !== activeVersionId),
            [(s) => s.lastActivityOn],
            ["desc"]
          ).map((m) => (
            <ListItem key={m.id} disableGutters disablePadding>
              <ListItemButton
                selected={m.id === activeVersionId}
                onClick={() => {
                  onCancel();
                  onChoose(m.friendlyId);
                }}
              >
                <ListItemText
                  primary={DateTime.fromISO(m.lastActivityOn).toLocaleString(
                    DateTime.DATE_FULL
                  )}
                  secondary={m.draft ? "(draft)" : ""}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
