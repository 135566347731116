import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
} from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { orderBy } from "lodash";
import { useCreateAnnotation } from "../../hooks/annotation/useCreateAnnotation";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  Annotation_Query$data,
  Annotation_Query$key,
} from "./__generated__/Annotation_Query.graphql";
import AnnotationReply from "./AnnotationReply";
import AnnotationContent from "./AnnotationContent";
import { useSearchParams } from "react-router-dom";
import { yellow } from '@mui/material/colors';

interface Props {
  readOnly: boolean;
  query: Annotation_Query$key;
}
export default function Annotation({ readOnly, query }: Props) {
  const [params] = useSearchParams();
  const annotationIdParam = params.get("annotationId");

  const data = useFragment(
    graphql`
      fragment Annotation_Query on Annotation {
        ...AnnotationContent_Query
        id
        friendlyId
        versionId
        replyTo
        createdBy {
          name
          picture
        }
        replies {
          __id
          edges {
            node {
              id
              friendlyId
              lastActivityOn
              ...AnnotationReply_Query
            }
          }
        }
      }
    `,
    query
  );

  const [createAnnotation] = useCreateAnnotation();

  const [reply, setReply] = useState<string>();
  const onReply = (annotation: Annotation_Query$data) => {
    if (!reply) {
      return;
    }

    createAnnotation({
      versionId: annotation.versionId,
      replyTo: annotation.id,
      content: reply,
      connections: data.replies ? [data.replies.__id] : [],
    });
    setReply("");
  };

  return (
    <ListItem
      key={data.id}
      id={data.friendlyId}
      alignItems="flex-start"
      sx={{
        paddingX: 0,
        backgroundColor:
          data.friendlyId === annotationIdParam ? yellow[300] : "initial",
      }}
    >
      <ListItemAvatar>
        <Avatar
          alt={data.createdBy.name}
          src={data.createdBy.picture ?? undefined}
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={<AnnotationContent query={data} />}
        secondary={
          <>
            {data.replies?.edges && data.replies.edges.length > 0 && (
              <Accordion
                defaultExpanded={
                  !!data.replies.edges.find(
                    (f) => f.node.friendlyId === annotationIdParam
                  )
                }
                disableGutters
                elevation={0}
                variant="elevation"
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="replies"
                  sx={{
                    minHeight: "unset",
                    padding: 0,
                    color: "primary.main",
                    ".MuiAccordionSummary-content": {
                      marginY: 1,
                    },
                  }}
                >
                  <Typography>
                    {data.replies.edges.length}{" "}
                    {data.replies.edges.length === 1 ? "Reply" : "Replies"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    borderLeft: "1px solid",
                    borderLeftColor: "divider",
                    paddingY: 0,
                    paddingRight: 0,
                  }}
                >
                  <List disablePadding sx={{ flexGrow: 1, overflow: "auto" }}>
                    {orderBy(
                      data.replies.edges
                        .filter((f) => !!f)
                        .map((m) => m.node)
                        .filter((f) => !!f),
                      [(s) => s.lastActivityOn],
                      ["asc"]
                    ).map((m) => (
                      <AnnotationReply key={m.id} query={m} />
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            )}

            {!readOnly && !data.replyTo && (
              <FormControl
                sx={{ marginTop: 1, width: "100%" }}
                variant="filled"
                hiddenLabel
                fullWidth
              >
                <InputLabel>Reply</InputLabel>
                <FilledInput
                  multiline
                  value={reply}
                  onChange={(ev) => setReply(ev.target.value)}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{ alignSelf: "end", margin: 1 }}
                    >
                      <IconButton
                        aria-label="send"
                        edge="end"
                        onClick={() => onReply(data)}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
          </>
        }
      />
    </ListItem>
  );
}
