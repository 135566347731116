import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useSetUserRole_Mutation$variables } from "./__generated__/useSetUserRole_Mutation.graphql";

export const useSetUserRole = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useSetUserRole_Mutation($id: ID!, $role: UserRole!) {
      setUserRole(input: { id: $id, role: $role }) {
        user {
          id
          role
        }
      }
    }
  `);

  const func = (variables: useSetUserRole_Mutation$variables) => {
    mutation({
      variables,
    });
  };

  return [func, inFlight] as [
    (variables: useSetUserRole_Mutation$variables) => void,
    boolean
  ];
};
