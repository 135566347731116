/**
 * @generated SignedSource<<5819a613de3649d8931a37247cb2efbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpdateUser_Mutation$variables = {
  email: string;
  id: string;
  name: string;
  picture?: string | null | undefined;
};
export type useUpdateUser_Mutation$data = {
  readonly updateProfile: {
    readonly user: {
      readonly email: string;
      readonly externalId: string | null | undefined;
      readonly id: string;
      readonly name: string;
      readonly picture: string | null | undefined;
    };
  };
};
export type useUpdateUser_Mutation = {
  response: useUpdateUser_Mutation$data;
  variables: useUpdateUser_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "picture"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "picture",
            "variableName": "picture"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "kind": "LinkedField",
    "name": "updateProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "picture",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateUser_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpdateUser_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "8031dc93a441e36155cd11a62cc498b9",
    "id": null,
    "metadata": {},
    "name": "useUpdateUser_Mutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateUser_Mutation(\n  $id: ID!\n  $name: String!\n  $email: String!\n  $picture: String\n) {\n  updateProfile(input: {id: $id, name: $name, email: $email, picture: $picture}) {\n    user {\n      id\n      externalId\n      name\n      email\n      picture\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbcf4be1e85e37f9a6ac3cda194ae232";

export default node;
