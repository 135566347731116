/**
 * @generated SignedSource<<a93ea027c16ea7a67b6866a8b5336246>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserRole = "ADMIN" | "READ" | "WRITE" | "%future added value";
export type useSetUserRole_Mutation$variables = {
  id: string;
  role: UserRole;
};
export type useSetUserRole_Mutation$data = {
  readonly setUserRole: {
    readonly user: {
      readonly id: string;
      readonly role: UserRole;
    };
  };
};
export type useSetUserRole_Mutation = {
  response: useSetUserRole_Mutation$data;
  variables: useSetUserRole_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "role"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "role",
            "variableName": "role"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "kind": "LinkedField",
    "name": "setUserRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSetUserRole_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSetUserRole_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a01b0f2d4f95c435fa7baaa68c5148c",
    "id": null,
    "metadata": {},
    "name": "useSetUserRole_Mutation",
    "operationKind": "mutation",
    "text": "mutation useSetUserRole_Mutation(\n  $id: ID!\n  $role: UserRole!\n) {\n  setUserRole(input: {id: $id, role: $role}) {\n    user {\n      id\n      role\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c54a63af0c41d90aa103bf2810e5e397";

export default node;
