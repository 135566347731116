import {
  Chip,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FormEvent, Suspense, useContext, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { sidebarContext } from "../../Contexts/SidebarContext";
import SidebarPaneHeader from "../../Sidebars/SidebarPaneHeader";
import {
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useNavigate, useParams } from "react-router-dom";
import { Search_Query } from "./__generated__/Search_Query.graphql";
import { yellow } from "@mui/material/colors";
import { random } from "lodash";

const query = graphql`
  query Search_Query($filter: SearchInput!) {
    search(filter: $filter) {
      results {
        documentId
        versionId
        annotationId
        type
        text
        highlightRange {
          sourcePosition
          length
        }
      }
    }
  }
`;
export default function Search() {
  const { projectId } = useParams();

  const environment = useRelayEnvironment();

  const [queryRef, loadQuery] = useQueryLoader<Search_Query>(query);

  const { leftSidebarPane, setLeftSidebarPane } = useContext(sidebarContext);

  const [filter, setFilter] = useState("");

  const search = (ev: FormEvent) => {
    ev.preventDefault();

    if (filter && filter.length >= 3 && projectId) {
      loadQuery(
        {
          filter: {
            filter,
            friendlyProjectId: projectId,
          },
        },
        { fetchPolicy: "network-only", __environment: environment }
      );
    }
  };

  return (
    <>
      {leftSidebarPane === "Search" && (
        <Stack
          useFlexGap
          gap={1}
          className="Search"
          sx={{ minWidth: "300px", maxWidth: "500px", height: "100%" }}
        >
          <SidebarPaneHeader
            header="Search"
            onClose={() => setLeftSidebarPane("None")}
          />
          <form onSubmit={search}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="search-field">Filter</InputLabel>
              <Input
                id="search-field"
                type="text"
                value={filter}
                onChange={(ev) => setFilter(ev.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search" type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>

          {queryRef && (
            <Suspense fallback={<></>}>
              <RenderSearchResults queryRef={queryRef} />
            </Suspense>
          )}

          {!queryRef && (
            <Typography
              variant="body2"
              alignSelf="center"
              color="grey.700"
              marginTop={2}
            >
              Enter at least 3 characters to search
            </Typography>
          )}
        </Stack>
      )}
    </>
  );
}

function RenderSearchResults({
  queryRef,
}: {
  queryRef: PreloadedQuery<Search_Query, Record<string, unknown>>;
}) {
  const { projectId } = useParams();
  const { setRightSidebarPane } = useContext(sidebarContext);
  const navigate = useNavigate();
  const data = usePreloadedQuery<Search_Query>(query, queryRef);

  if (!data.search.results.length) {
    return (
      <Typography
        variant="body2"
        alignSelf="center"
        color="grey.700"
        marginTop={2}
      >
        No Results Found
      </Typography>
    );
  }

  return (
    <nav style={{ overflow: "auto", height: "100%" }}>
      <List>
        {data.search.results.map((m) => (
          <ListItem
            key={m.annotationId ?? m.versionId ?? m.documentId}
            disablePadding
            disableGutters
          >
            <ListItemButton
              onClick={() => {
                if (m.type === "DOCUMENT") {
                  navigate(`/${projectId}/doc/${m.documentId}`);
                } else if (m.type === "VERSION") {
                  navigate(`/${projectId}/doc/${m.documentId}/${m.versionId}`);
                } else if (m.type === "ANNOTATION") {
                  navigate(
                    `/${projectId}/doc/${m.documentId}/${m.versionId}?annotationId=${m.annotationId}`
                  );
                  setRightSidebarPane("Annotations");
                }
              }}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "3",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {RenderSearchResultText(m)}
                  </Typography>
                }
                secondary={<Chip label={m.type} variant="outlined" />}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </nav>
  );
}

function RenderSearchResultText({
  text,
  highlightRange,
}: {
  text: string;
  highlightRange: readonly {
    readonly sourcePosition: number;
    readonly length: number;
  }[];
}) {
  const id = random();
  if (!highlightRange.length) {
    return <span>{text}</span>;
  }

  const sections = [];
  if (highlightRange[0].sourcePosition !== 0) {
    sections.push(
      <Typography key={id + "-first"} component="span">
        {text.substring(0, highlightRange[0].sourcePosition)}
      </Typography>
    );
  }

  for (var i = 0; i < highlightRange.length; i++) {
    const range = highlightRange[i];
    sections.push(
      <Typography
        component="span"
        sx={{ backgroundColor: yellow[300] }}
        key={id + "-highlight-" + i}
      >
        {text.substring(
          range.sourcePosition,
          range.sourcePosition + range.length
        )}
      </Typography>
    );

    if (i !== highlightRange.length - 1) {
      const next = highlightRange[i + 1];
      sections.push(
        <Typography component="span" key={id + "-text-" + i}>
          {text.substring(
            range.sourcePosition + range.length,
            next.sourcePosition
          )}
        </Typography>
      );
    }
  }

  var lastRange = highlightRange[highlightRange.length - 1];
  if (lastRange.sourcePosition + lastRange.length !== text.length) {
    sections.push(
      <Typography component="span" key={id + "-last"}>
        {text.substring(lastRange.sourcePosition + lastRange.length)}
      </Typography>
    );
  }

  return sections;
}
