import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

export const useExportProject = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useExportProject_Mutation($id: ID!) {
      export(input: { id: $id }) {
        exportPath
      }
    }
  `);

  const func = (id: string) => {
    mutation({
      variables: {
        id,
      },
      onCompleted(response) {
        const {
          export: { exportPath },
        } = response as { export: { exportPath: string } };
        window.open(exportPath, "_blank");
      },
    });
  };

  return [func, inFlight] as [(id: string) => void, boolean];
};
