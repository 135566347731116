import ProjectCard from "./ProjectCard";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { orderBy } from "lodash";
import { ProjectList_Query$key } from "./__generated__/ProjectList_Query.graphql";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

interface Props {
  query?: ProjectList_Query$key | null;
  onEdit: (project: { id: string; name: string; description: string }) => void;
  editing: (id: string) => boolean
}
export default function ProjectList({ query, onEdit, editing }: Props) {
  const data = useFragment(
    graphql`
      fragment ProjectList_Query on ProjectsConnection {
        edges {
          node {
            id
            archived
            lastActivityOn
            ...ProjectCard_Query
          }
        }
      }
    `,
    query
  );

  return (
    <Grid container spacing={2} marginBottom={2} sx={{ overflow: "auto" }}>
      {orderBy(
        data?.edges
          ?.filter((f) => !!f)
          .map((m) => m.node)
          .filter((f) => !!f),
        [(s) => s.archived, (s) => s.lastActivityOn],
        ["asc", "desc"]
      ).map((m) => (
        <Grid key={m.id}>
          <ProjectCard
            query={m}
            onEdit={onEdit}
            editing={editing}
          ></ProjectCard>
        </Grid>
      ))}
    </Grid>
  );
}
