import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

export const useChangePassword = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useChangePassword_Mutation($id: ID!) {
      changePassword(input: { id: $id }) {
        user {
          id
        }
      }
    }
  `);

  const func = (userId: string) => {
    mutation({
      variables: {
        id: userId,
      },
    });
  };

  return [func, inFlight] as [(userId: string) => void, boolean];
};
