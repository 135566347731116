const env = {
  apiRoot: process.env.REACT_APP_API_ROOT ?? window.origin + "/api",
  graphQLEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT ?? window.origin + "/api/graphql",
  authentication: {
    domain: process.env.REACT_APP_AUTHENTICATION_DOMAIN || "",
    clientId: process.env.REACT_APP_AUTHENTICATION_CLIENT_ID || "",
    audience: process.env.REACT_APP_AUTHENTICATION_AUDIENCE || "",
    scope: process.env.REACT_APP_AUTHENTICATION_SCOPE || "",
    organization: {
      local: process.env.REACT_APP_LOCAL_ORGANIZATION || "",
      acme: process.env.REACT_APP_ACME_ORGANIZATION || ""
    }
  },
};

export default env;
