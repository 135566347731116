import { useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { useCreateProject_Mutation$variables } from "./__generated__/useCreateProject_Mutation.graphql";

export const useCreateProject = () => {
  const navigate = useNavigate();

  const [mutation, inFlight] = useMutation(graphql`
    mutation useCreateProject_Mutation(
      $name: String!
      $description: String!
      $connections: [ID!]!
    ) {
      createProject(input: { name: $name, description: $description }) {
        project
          @appendNode(connections: $connections, edgeTypeName: "ProjectsEdge") {
          id
          friendlyId
          name
          description
          archived
          lastActivityOn
        }
      }
    }
  `);

  const func = (variables: useCreateProject_Mutation$variables) => {
    mutation({
      variables,
      onCompleted(response) {
        const {
          createProject: {
            project: { friendlyId },
          },
        } = response as { createProject: { project: { friendlyId: string } } };
        navigate(`/${friendlyId}`);
      },
    });
  };

  return [func, inFlight] as [
    (variables: useCreateProject_Mutation$variables) => void,
    boolean
  ];
};
