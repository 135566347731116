import { useContext } from "react";
import { Box, Stack } from "@mui/material";
import HeaderTree from "./HeaderTree";
import SidebarPaneHeader from "../../Sidebars/SidebarPaneHeader";
import { sidebarContext } from "../../Contexts/SidebarContext";
import parse from "html-react-parser";
import { random } from "lodash";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { TableOfContents_Query$key } from "./__generated__/TableOfContents_Query.graphql";

interface Props {
  query?: TableOfContents_Query$key | null;
}
export default function TableOfContents({ query }: Props) {
  const activeVersionData = useFragment(
    graphql`
      fragment TableOfContents_Query on ContentVersion {
        html
      }
    `,
    query
  );

  const { leftSidebarPane, setLeftSidebarPane } = useContext(sidebarContext);

  const nodes = parse(activeVersionData?.html ?? "");

  const arrNodes =
    typeof nodes === "string"
      ? []
      : nodes.constructor.name === "Array"
      ? (nodes as JSX.Element[])
      : ([nodes] as JSX.Element[]);

  const headerNodes = getHeaders(arrNodes);

  return (
    <>
      {leftSidebarPane === "Table of Contents" && (
        <Stack
          useFlexGap
          gap={1}
          className="TableOfContents"
          sx={{ minWidth: "200px", height: "100%" }}
        >
          <SidebarPaneHeader
            header="Table of Contents"
            onClose={() => setLeftSidebarPane("None")}
          />
          <Box sx={{ overflow: "auto" }}>
            {headerNodes.map((m) => (
              <HeaderTree key={m.id || random()} node={m} />
            ))}
          </Box>
        </Stack>
      )}
    </>
  );
}

export type headerTreeNode = {
  parent?: headerTreeNode;
  id?: string;
  header?: string;
  level: number;
  children: headerTreeNode[];
};

function getHeaders(nodes: JSX.Element[]): headerTreeNode[] {
  const supportedTypes = ["h1", "h2", "h3", "h4", "h5", "h6"];
  const headers = nodes.filter((f) => supportedTypes.indexOf(f.type) !== -1);

  if (headers.length === 0) {
    return [];
  }

  let currentNode: headerTreeNode = {
    level: 1,
    id: headers[0].props.id,
    header: headers[0].props.children,
    children: [],
  };
  const list = [currentNode];

  for (let i = 1; i < headers.length; i++) {
    const header = headers[i];
    const headerLevel = parseInt(header.type[1]);

    if (headerLevel === currentNode.level) {
      const newNode = {
        parent: currentNode.parent,
        level: headerLevel,
        id: header.props.id,
        header: header.props.children,
        children: [],
      };

      if (currentNode.parent) {
        currentNode.parent.children.push(newNode);
      } else {
        list.push(newNode);
      }
    } else if (headerLevel > currentNode.level) {
      for (let j = currentNode.level + 1; j <= headerLevel; j++) {
        const nextNode: headerTreeNode = {
          parent: currentNode,
          level: j,
          id: j === headerLevel ? header.props.id : undefined,
          header: j === headerLevel ? header.props.children : undefined,
          children: [],
        };

        currentNode.children.push(nextNode);
        currentNode = nextNode;
      }
    } else {
      for (let j = currentNode.level; j > headerLevel; j--) {
        currentNode = currentNode.parent as headerTreeNode;
      }

      currentNode = {
        parent: currentNode.parent,
        level: headerLevel,
        id: header.props.id,
        header: header.props.children,
        children: [],
      };

      if (currentNode.parent) {
        currentNode.parent.children.push(currentNode);
      } else {
        list.push(currentNode);
      }
    }
  }

  return list;
}
