import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  project?: { id?: string; name: string; description?: string };
  open: boolean;
  onCancel: VoidFunction;
  onSave: (project: { id?: string; name: string; description: string }) => void;
}
export default function EditProjectModal({
  project,
  open,
  onCancel,
  onSave,
}: Props) {
  const defaultTextFieldState = {
    value: "",
    error: false,
    errorMessage: "",
  };

  const [name, setName] = useState(defaultTextFieldState);
  const [description, setDescription] = useState(project?.description ?? "");

  const handleNameChange = (value: string) => {
    if (!value?.length) {
      setName({ value, error: true, errorMessage: "Name is required" });
    } else if (value.length > 20) {
      setName({
        value,
        error: true,
        errorMessage: "Name is limited to 20 characters",
      });
    } else {
      setName({ value, error: false, errorMessage: "" });
    }
  };

  const cancel = () => {
    onCancel();
    setName(defaultTextFieldState);
    setDescription("");
  };

  const saveProject = () => {
    onSave({ id: project?.id, name: name.value, description });
  };

  useEffect(() => {
    if (project) {
      // assuming everything coming from the DB is valid
      // we re-validate as soon as anything changes anyway
      setName({ value: project.name, error: false, errorMessage: "" });
      setDescription(project.description ?? "");
    }
  }, [project]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={cancel}
      aria-labelledby="project-modal-title"
    >
      <DialogTitle id="project-modal-title">
        {project ? `Edit ${project.name}` : "Create Project"}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            fullWidth
            required
            label="Name"
            variant="standard"
            value={name.value}
            error={name.error}
            helperText={name.errorMessage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleNameChange(event.target.value);
            }}
          />
          <TextField
            fullWidth
            label="Description"
            variant="standard"
            multiline
            rows={3}
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={saveProject} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}