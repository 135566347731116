/**
 * @generated SignedSource<<c376a86e0cc71f84f25008a2451aeb6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useCreateAnnotation_Mutation$variables = {
  connections: ReadonlyArray<string>;
  content: string;
  replyTo?: string | null | undefined;
  selectionText?: string | null | undefined;
  versionId: string;
};
export type useCreateAnnotation_Mutation$data = {
  readonly createAnnotation: {
    readonly annotation: {
      readonly content: string;
      readonly createdBy: {
        readonly email: string;
        readonly id: string;
        readonly name: string;
        readonly picture: string | null | undefined;
      };
      readonly friendlyId: string;
      readonly id: string;
      readonly replies: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          };
        }> | null | undefined;
      } | null | undefined;
      readonly replyTo: string | null | undefined;
      readonly selectionText: string | null | undefined;
      readonly updatedOn: any;
      readonly versionId: string;
    };
  };
};
export type useCreateAnnotation_Mutation = {
  response: useCreateAnnotation_Mutation$data;
  variables: useCreateAnnotation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "content"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "replyTo"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectionText"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionId"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "replyTo",
        "variableName": "replyTo"
      },
      {
        "kind": "Variable",
        "name": "selectionText",
        "variableName": "selectionText"
      },
      {
        "kind": "Variable",
        "name": "versionId",
        "variableName": "versionId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Annotation",
  "kind": "LinkedField",
  "name": "annotation",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectionText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replyTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepliesConnection",
      "kind": "LinkedField",
      "name": "replies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RepliesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Annotation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateAnnotation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CreateAnnotationPayload",
        "kind": "LinkedField",
        "name": "createAnnotation",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCreateAnnotation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CreateAnnotationPayload",
        "kind": "LinkedField",
        "name": "createAnnotation",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "annotation",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AnnotationsEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2608401d6f30302ddd9238e95af269d8",
    "id": null,
    "metadata": {},
    "name": "useCreateAnnotation_Mutation",
    "operationKind": "mutation",
    "text": "mutation useCreateAnnotation_Mutation(\n  $versionId: ID!\n  $content: String!\n  $selectionText: String\n  $replyTo: ID\n) {\n  createAnnotation(input: {versionId: $versionId, content: $content, selectionText: $selectionText, replyTo: $replyTo}) {\n    annotation {\n      id\n      versionId\n      friendlyId\n      selectionText\n      content\n      replyTo\n      updatedOn\n      replies {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      createdBy {\n        id\n        name\n        email\n        picture\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f45df4ade8e51ba591fb2eaa2458043";

export default node;
