import {
  Box,
  Stack,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { QueryRenderer, useRelayEnvironment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  UserRoot_Query,
  UserRoot_Query$data,
} from "./__generated__/UserRoot_Query.graphql";
import UserListHeader from "./UserListHeader";
import UserRow from "./UserRow";
import { useContext, useState } from "react";
import CreateUserModal from "./CreateUserModal";
import { useCreateUser } from "./hooks/useCreateUser";
import { useCreateUser_Mutation$variables } from "./hooks/__generated__/useCreateUser_Mutation.graphql";
import { useSetUserRole } from "./hooks/useSetUserRole";
import { UserRole } from "./hooks/__generated__/useSetUserRole_Mutation.graphql";
import SetUserRoleModal from "./SetUserRoleModal";
import { confirmationDialogContext } from "../ConfirmationDialogContext";
import { useDeleteUser } from "./hooks/useDeleteUser";
import { orderBy } from "lodash";
import { useUser } from "../UserContext";

export default function UserRoot() {
  const environment = useRelayEnvironment();

  const query = graphql`
    query UserRoot_Query {
      users {
        __id
        edges {
          node {
            id
            name
            role
            ...UserRow_Query
          }
        }
      }
    }
  `;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  type userType = Parameters<Parameters<typeof UserRow>[0]["openMenu"]>[1];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userWithMenu, setUserWithMenu] = useState<userType>();
  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    document: userType
  ) => {
    setAnchorEl(event.currentTarget);
    setUserWithMenu(document);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setUserWithMenu(undefined);
  };

  const { confirm } = useContext(confirmationDialogContext);

  const [createModelOpen, setCreateModalOpen] = useState(false);
  const [userRoleModalOpen, setUserRoleModalOpen] = useState(false);

  const { user } = useUser();
  const [createUser] = useCreateUser();
  const [setUserRole] = useSetUserRole();
  const [deleteUser] = useDeleteUser();

  const onInviteUser =
    (connectionId?: string | null) =>
    (user: Omit<useCreateUser_Mutation$variables, "connections">) => {
      if (!connectionId) {
        return;
      }

      createUser({ ...user, connections: [connectionId] });
      setCreateModalOpen(false);
    };

  const onSetUserRole = (role: UserRole) => {
    if (!userWithMenu) {
      return;
    }

    setUserRole({ id: userWithMenu.id, role });
    setUserRoleModalOpen(false);
    closeMenu();
  };

  const onCancelSetUserRole = () => {
    setUserRoleModalOpen(false);
    closeMenu();
  };

  const confirmDeleteUser = () => {
    if (userWithMenu) {
      confirm(
        "Confirm Delete",
        `Confirm you want to delete user '${userWithMenu.name}'.`,
        "Delete",
        () => () => {
          deleteUser(userWithMenu.id);
          closeMenu();
        }
      );
    }
  };

  const roleOrder = {
    ADMIN: 0,
    WRITE: 1,
    READ: 2,

    // this is some weird Relay generator thing for enums
    "%future added value": 99,
  };

  return (
    <Box className="UserRoot" marginX={5} sx={{ height: "100%" }}>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        MenuListProps={{
          "aria-label": "actions",
        }}
      >
        <MenuItem onClick={() => setUserRoleModalOpen(true)}>
          Change Role
        </MenuItem>
        <MenuItem onClick={confirmDeleteUser}>Delete</MenuItem>
      </Menu>

      <SetUserRoleModal
        user={userWithMenu}
        open={userRoleModalOpen}
        onCancel={onCancelSetUserRole}
        onSetUserRole={onSetUserRole}
      />

      <Stack sx={{ height: "100%" }}>
        <UserListHeader onInviteUser={() => setCreateModalOpen(true)} />

        <QueryRenderer<UserRoot_Query>
          environment={environment}
          query={query}
          render={({
            error,
            props,
          }: {
            error: Error | null;
            props: UserRoot_Query$data | null;
          }) => (
            <>
              <CreateUserModal
                open={createModelOpen}
                onCancel={() => setCreateModalOpen(false)}
                onInvite={onInviteUser(props?.users?.__id)}
              />
              <Box
                sx={{
                  alignContent: "start",
                  height: "100%",
                  overflow: "auto",
                  paddingRight: 2,
                  paddingBottom: 2,
                  display: "grid",
                  gridTemplateColumns: matches
                    ? user?.role === "ADMIN"
                      ? "auto 1fr 1fr auto auto"
                      : "auto 1fr auto auto"
                    : user?.role === "ADMIN"
                    ? "auto 1fr auto auto"
                    : "auto 1fr auto",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                {orderBy(
                  props?.users?.edges
                    ?.filter((f) => !!f)
                    .map((m) => m.node)
                    .filter((f) => !!f),
                  [(m) => roleOrder[m.role], (m) => m.name],
                  ["asc", "asc"]
                ).map((data) => (
                  <UserRow
                    key={data.id}
                    query={data}
                    open={!!anchorEl}
                    openMenu={openMenu}
                  />
                ))}
              </Box>
            </>
          )}
          variables={{}}
        />
      </Stack>
    </Box>
  );
}
