import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { apiContext } from "./ApiContext";
import { useAuth0 } from "@auth0/auth0-react";

type userRole = "ADMIN" | "READ" | "WRITE";
type userType = {
  readonly email: string;
  readonly externalId: string | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly picture: string | null | undefined;
  readonly role: userRole;
};

type userContextType = {
  user?: userType | null;
};

const userContext = createContext<userContextType>({});

export const useUser = () => useContext(userContext);

export default function UserContext({ children }: { children: ReactNode }) {
  const { isAuthenticated } = useAuth0();
  const { post } = useContext(apiContext);

  const [user, setUser] = useState<userType | undefined | null>();

  useEffect(() => {
    const func = async () => {
      if (!isAuthenticated) {
        setUser(undefined);
        return;
      }

      const response = await post<{ data: { self: userType } }>("graphql", {
        query: `query UserContext_Query {
            self {
              id
              externalId
              name
              email
              role
              picture
            }
          }`,
      });
      setUser(response.data.self);
    };
    func();
  }, [post, isAuthenticated]);

  return (
    <userContext.Provider value={{ user }}>{children}</userContext.Provider>
  );
}

export function Requires({
  requires,
  or,
  children,
}: {
  requires: userRole;
  or?: () => boolean;
  children: ReactNode;
}) {
  const roleMap = {
    READ: ["READ"],
    WRITE: ["READ", "WRITE"],
    ADMIN: ["READ", "WRITE", "ADMIN"],
  };

  const userRole = useUser().user?.role;

  return (userRole && roleMap[userRole].indexOf(requires) !== -1) || (or && or()) ? (
    <>{children}</>
  ) : null;
}
