import { Button } from "@mui/material";
import Header from "../Header";
import { Requires } from "../UserContext";

export default function UserListHeader({
  onInviteUser,
}: {
  onInviteUser: VoidFunction;
}) {
  return (
    <Header
      header="Users"
      action={
        <Requires requires="ADMIN">
          <Button variant="contained" onClick={onInviteUser}>
            Invite User
          </Button>
        </Requires>
      }
    />
  );
}
