import { Change, diffWordsWithSpace } from "diff";

export default function Diff({ str1, str2 }: { str1: string; str2: string }) {
  const diff = diffWordsWithSpace(str1, str2);

  return (
    <pre>
      {diff.map((m, i) => (
        <RenderDiff key={i} {...m} />
      ))}
    </pre>
  );
}

function RenderDiff({ value, added, removed }: Change) {
  const color = added ? "#abf2bc" : removed ? "#ffc0c0" : "initial";

  const render = value
    .split(/\n/)
    .flatMap((m, i) => [
      <span key={i + "-span"} style={{ backgroundColor: color }}>
        {m}
      </span>,
      <br key={i + "-br"} />,
    ])
    .slice(0, -1);

  return <>{render}</>;
}
