import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { EmbeddedResource_Query$key } from "./__generated__/EmbeddedResource_Query.graphql";

interface Props {
  query: EmbeddedResource_Query$key;
}
export default function EmbeddedResource({ query }: Props) {
  const data = useFragment(
    graphql`
      fragment EmbeddedResource_Query on Document {
        name
        externalLink
      }
    `,
    query
  );

  return (
    <iframe
      title={data.name}
      height="100%"
      width="100%"
      src={data.externalLink!}
    />
  );
}
