import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useUpdateDocument_Mutation$variables } from "./__generated__/useUpdateDocument_Mutation.graphql";

export const useUpdateDocument = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useUpdateDocument_Mutation(
      $id: ID!
      $name: String!
      $externalLink: String
    ) {
      updateDocument(
        input: { id: $id, name: $name, externalLink: $externalLink }
      ) {
        document {
          id
          name
          lastActivityOn
        }
      }
    }
  `);

  const func = (variables: useUpdateDocument_Mutation$variables) => {
    mutation({
      variables,
    });
  };

  return [func, inFlight] as [
    (variables: useUpdateDocument_Mutation$variables) => void,
    boolean
  ];
};
