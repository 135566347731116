import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { useCreateUser_Mutation$variables } from "./hooks/__generated__/useCreateUser_Mutation.graphql";
import { useState } from "react";

interface Props {
  open: boolean;
  onCancel: VoidFunction;
  onInvite: (
    user: Omit<useCreateUser_Mutation$variables, "connections">
  ) => void;
}
export default function CreateUserModal({ open, onCancel, onInvite }: Props) {
  const roles = ["Read", "Write", "Admin"];
  type roleType = "READ" | "WRITE" | "ADMIN";

  const defaultTextFieldState = {
    value: "",
    error: false,
    errorMessage: "",
  };

  const [email, setEmail] = useState(defaultTextFieldState);
  const [name, setName] = useState(defaultTextFieldState);
  const [role, setRole] = useState<roleType>("READ");

  const handleEmailChange = (value: string) => {
    if (!value?.length) {
      setEmail({ value, error: true, errorMessage: "Email is required" });
    } else if (
      value.indexOf("@") === -1 ||
      value.lastIndexOf(".") < value.indexOf("@")
    ) {
      setEmail({ value, error: true, errorMessage: "Invalid email" });
    } else {
      setEmail({ value, error: false, errorMessage: "" });
    }
  };

  const handleNameChange = (value: string) => {
    if (!value?.length) {
      setName({ value, error: true, errorMessage: "Name is required" });
    } else {
      setName({ value, error: false, errorMessage: "" });
    }
  };

  const handleRoleChange = (value: string) => {
    if (
      !value?.length ||
      roles.map((m) => m.toUpperCase()).indexOf(value) === -1
    ) {
      return;
    }

    setRole(value as roleType);
  };

  const inviteUser = () => {
    onInvite({
      email: email.value,
      name: name.value,
      role,
    });
  };

  const cancel = () => {
    setEmail(defaultTextFieldState);
    setName(defaultTextFieldState);
    setRole("READ");
    onCancel();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={cancel}
      aria-labelledby="invite-user-title"
    >
      <DialogTitle id="invite-user-title">Invite User</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            autoFocus
            fullWidth
            required
            label="Email"
            variant="standard"
            value={email.value}
            error={email.error}
            helperText={email.errorMessage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleEmailChange(event.target.value);
            }}
          />
          <TextField
            autoFocus
            fullWidth
            required
            label="Name"
            variant="standard"
            value={name.value}
            error={name.error}
            helperText={name.errorMessage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleNameChange(event.target.value);
            }}
          />
          <FormControl size="small">
            <InputLabel id="create-user-role-label">Role</InputLabel>
            <Select
              labelId="create-user-role-label"
              label="Role"
              value={role}
              onChange={(ev) => handleRoleChange(ev.target.value)}
            >
              {roles.map((m) => (
                <MenuItem key={m} value={m.toUpperCase()}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={inviteUser} autoFocus>
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
