/**
 * @generated SignedSource<<693ad1ee56e78dafac3d3ace27e31cb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DocumentType = "FIGMA" | "LINK" | "MARKDOWN" | "MERMAID" | "PDF" | "%future added value";
export type useCreateDocument_Mutation$variables = {
  connections: ReadonlyArray<string>;
  externalLink?: string | null | undefined;
  name: string;
  projectId: string;
  type: DocumentType;
};
export type useCreateDocument_Mutation$data = {
  readonly createDocument: {
    readonly document: {
      readonly externalLink: string | null | undefined;
      readonly favorite: boolean;
      readonly friendlyId: string;
      readonly id: string;
      readonly lastActivityOn: any;
      readonly name: string;
      readonly projectId: string;
      readonly type: DocumentType;
    };
  };
};
export type useCreateDocument_Mutation = {
  response: useCreateDocument_Mutation$data;
  variables: useCreateDocument_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "externalLink"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "externalLink",
        "variableName": "externalLink"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "projectId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Document",
  "kind": "LinkedField",
  "name": "document",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastActivityOn",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateDocument_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CreateDocumentPayload",
        "kind": "LinkedField",
        "name": "createDocument",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCreateDocument_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CreateDocumentPayload",
        "kind": "LinkedField",
        "name": "createDocument",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "document",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "DocumentsEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b51d62bf6d920d42e5b69556de1370c0",
    "id": null,
    "metadata": {},
    "name": "useCreateDocument_Mutation",
    "operationKind": "mutation",
    "text": "mutation useCreateDocument_Mutation(\n  $projectId: ID!\n  $name: String!\n  $type: DocumentType!\n  $externalLink: String\n) {\n  createDocument(input: {projectId: $projectId, name: $name, type: $type, externalLink: $externalLink}) {\n    document {\n      id\n      friendlyId\n      name\n      projectId\n      type\n      externalLink\n      favorite\n      lastActivityOn\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0446d00daa358a78ea0b99bf79e9521";

export default node;
