import { useContext } from "react";
import { Link, Stack, Typography } from "@mui/material";
import SidebarPaneHeader from "../../Sidebars/SidebarPaneHeader";
import { sidebarContext } from "../../Contexts/SidebarContext";

export default function FormatPane() {
  const { rightSidebarPane, setRightSidebarPane } = useContext(sidebarContext);

  return (
    <>
      {rightSidebarPane === "Format" && (
        <Stack
          useFlexGap
          gap={1}
          className="Format"
          sx={{ minWidth: "250px", height: "100%" }}
        >
          <SidebarPaneHeader
            header="Format"
            onClose={() => setRightSidebarPane("None")}
          />
          <Stack sx={{ overflow: "auto", paddingRight: 1, paddingBottom: 1 }}>
            <Typography variant="h5" component="h3">
              Headers
            </Typography>
            <Typography component="p">
              Headers are written in the format <Highlight text="# Header" />;
              the number of <Highlight text="#" /> (up to six) indicates the
              header level.
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginTop: 1 }}>
              Styling
            </Typography>
            <Typography component="p">
              Text can be styled by surrounding it with <Highlight text="*" />
              s. <Highlight text="*this is italic*" />,{" "}
              <Highlight text="**this is bold**" />, and{" "}
              <Highlight text="***this is italic and bold***" />.
              <br />
              <br />
              Horizontal rules can be made by putting <Highlight text="***" />{" "}
              on a line by itself.
              <br />
              <br />
              New paragraphs can be created with two new-line characters:
              <Highlight
                text={`This is paragraph 1...

And this is paragraph 2.`}
                component="pre"
              />
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginTop: 1 }}>
              Links
            </Typography>
            <Typography component="p">
              Links can be created in any of three ways. You can put carets
              around them: <Highlight text={`<${window.location.origin}>`} />,
              give them a label:{" "}
              <Highlight text={`[my link](${window.location.origin})`} />, or
              build a references list:{" "}
              <Highlight
                text={`[my link][reference label]
 [reference label]: ${window.location.origin}`}
                component="pre"
              />
              To link an image, simply put an <Highlight text="!" /> in front of
              it:{" "}
              <Highlight
                text={`![image alt text](${window.location.origin}/logo192.png)`}
              />
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginTop: 1 }}>
              Lists
            </Typography>
            <Typography component="p">
              Lists can be created by prefixing list items with{" "}
              <Highlight text="1." /> or <Highlight text="-" />:
              <Highlight
                text={`1. list item 1
2. list item 2

- list item 1
- list item 2`}
                component="pre"
              />
              Markdown will keep track of the correct numbers in numbered lists
              for you, it doesn't matter if you just do <Highlight text="1." />{" "}
              for each item, if you number them correctly, or if you have the
              numbers out of order.
              <br />
              <br />
              Nested lists work too
              <Highlight
                text={`1. list item 1
   1. list item 1.1
2. list item 2

- list item 1
  - list item 1.1
- list item 2`}
                component="pre"
              />
              <br />
              List items can contain multiple paragraphs; just make sure to
              prefix each paragraph with spaces; otherwise, it wil end the list.
              <Highlight
                text={`- list item 1

  list item 1 still
- list item 2`}
                component="pre"
              />
              <br />
              You can also write task lists, which will render as checkboxes:
              <Highlight
                text={`- [x] This checkbox will be checked
- [ ] This checkbox will not by checked`}
                component="pre"
              />
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginTop: 1 }}>
              Blockquotes
            </Typography>
            <Typography component="p">
              Blockquotes are prefixed with a <Highlight text={`> `} />. They
              can be nested an unlimited number of times.
              <Highlight
                text={`> my blockquote
> > my nested blockquote
> > > my deeply nested blockquote`}
                component="pre"
              />
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginTop: 1 }}>
              Code
            </Typography>
            <Typography component="p">
              To create an inline code element, surround it with backticks:{" "}
              <Highlight text={`\`this is an inline code span\``} />. To create
              a code block, prefix it with four spaces or surround it with a
              fence of backticks followed by a syntax highlight name:
              <Highlight
                text={`    code line 1
    code line 2

\`\`\`text
code line 1
code line 2
\`\`\``}
                component="pre"
              />
              The full list of syntax highlight codes can be found at{" "}
              <Link href="https://github.com/highlightjs/highlight.js/blob/main/SUPPORTED_LANGUAGES.md">
                https://github.com/highlightjs/highlight.js/blob/main/SUPPORTED_LANGUAGES.md
              </Link>
              .
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginTop: 1 }}>
              Tables
            </Typography>
            <Typography component="p">
              Tables can be implemented with the following syntax:
              <Highlight
                text={`| A header | A left-aligned column | A center-aligned column | A right-aligned column |
| --- |:--- |:---:| ---:|
| First | row | col 3 | col 4 |
| Second | row | col 3 | col 4 |`}
                component="pre"
              />
              While you can align your columns for easier reading while editing,
              it isn't necessary.
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}

function Highlight({
  text,
  component = "span",
}: {
  text: string;
  component?: "pre" | "span";
}) {
  const marginY = component === "span" ? "initial" : 0.125;

  return (
    <Typography
      sx={{
        padding: 0.25,
        borderRadius: 0.5,
        backgroundColor: "grey.300",
        marginY,
        overflow: "auto",
      }}
      component={component}
    >
      {text}
    </Typography>
  );
}
