import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useCreateAnnotation_Mutation$variables } from "./__generated__/useCreateAnnotation_Mutation.graphql";

export const useCreateAnnotation = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useCreateAnnotation_Mutation(
      $versionId: ID!
      $content: String!
      $selectionText: String
      $replyTo: ID
      $connections: [ID!]!
    ) {
      createAnnotation(
        input: {
          versionId: $versionId
          content: $content
          selectionText: $selectionText
          replyTo: $replyTo
        }
      ) {
        annotation 
          @appendNode(
            connections: $connections
            edgeTypeName: "AnnotationsEdge"
          ) {
          id
          versionId
          friendlyId
          selectionText
          content
          replyTo
          updatedOn
          replies {
            __id
            edges {
              node {
                id
              }
            }
          }
          createdBy {
            id
            name
            email
            picture
          }
        }
      }
    }
  `);

  const func = (variables: useCreateAnnotation_Mutation$variables) => {
    mutation({
      variables,
    });
  };

  return [func, inFlight] as [
    (variables: useCreateAnnotation_Mutation$variables) => void,
    boolean
  ];
};
