import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import ProjectRoot from "./Project/ListPage/ProjectRoot";
import DocumentRoot from "./Document/DocumentRoot";
import ReadDocumentPage from "./Document/ReadDocumentPage/ReadDocumentPage";
import EditDocumentPage from "./Document/EditDocumentPage/EditDocumentPage";
import DiffPage from "./Document/DiffPage/DiffPage";
import { useAuth0 } from "@auth0/auth0-react";
import App from "./App";
import UserProfileRoot from "./UserProfile/UserProfileRoot";
import UserRoot from "./User/UserRoot";
import HomeRoot from "./Home/HomeRoot";
import { Requires } from "./UserContext";

const AuthGuard = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return <Outlet />;
  }

  if (isLoading) {
    return <></>;
  }

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  }

  return <></>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route index element={<HomeRoot />} />
      <Route element={<AuthGuard />}>
        <Route path="profile" element={<UserProfileRoot />} />
        <Route path="users" element={<UserRoot />} />
        <Route path="projects" element={<ProjectRoot />} />
        <Route
          path=":projectId/doc?/:documentId?/:versionId?"
          element={<DocumentRoot />}
        >
          <Route index element={<ReadDocumentPage />} />
        </Route>
        <Route
          path=":projectId/doc/:documentId/:versionId"
          element={<DocumentRoot />}
        >
          <Route index element={<ReadDocumentPage />} />
          <Route
            path="edit"
            element={
              <Requires requires="WRITE">
                <EditDocumentPage />
              </Requires>
            }
          />
          <Route path="diff/:diffVersionId" element={<DiffPage />} />
        </Route>
      </Route>
    </Route>
  )
);

export default function Router() {
  return <RouterProvider router={router} />;
}
