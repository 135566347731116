/**
 * @generated SignedSource<<c8076cf4ae5c79bc35affd52c3dc811b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "FIGMA" | "LINK" | "MARKDOWN" | "MERMAID" | "PDF" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentDialogForm_Query$data = {
  readonly externalLink: string | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly type: DocumentType;
  readonly " $fragmentType": "DocumentDialogForm_Query";
};
export type DocumentDialogForm_Query$key = {
  readonly " $data"?: DocumentDialogForm_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentDialogForm_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentDialogForm_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalLink",
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "7989dda058498defd9eb4acb4ffab2e4";

export default node;
