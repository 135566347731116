import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { AnnotationReply_Query$key } from "./__generated__/AnnotationReply_Query.graphql";
import AnnotationContent from "./AnnotationContent";
import { useSearchParams } from "react-router-dom";
import { yellow } from "@mui/material/colors";

interface Props {
  query: AnnotationReply_Query$key;
}
export default function AnnotationReply({ query }: Props) {
  const [params] = useSearchParams();
  const annotationIdParam = params.get("annotationId");

  const data = useFragment(
    graphql`
      fragment AnnotationReply_Query on Annotation {
        ...AnnotationContent_Query
        id
        friendlyId
        createdBy {
          name
          picture
        }
      }
    `,
    query
  );

  return (
    <ListItem
      key={data.id}
      id={data.friendlyId}
      alignItems="flex-start"
      sx={{
        paddingX: 0,
        backgroundColor:
          data.friendlyId === annotationIdParam ? yellow[300] : "initial",
      }}
    >
      <ListItemAvatar>
        <Avatar
          alt={data.createdBy.name}
          src={data.createdBy.picture ?? undefined}
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <AnnotationContent query={data} />
        }
      />
    </ListItem>
  );
}
