/**
 * @generated SignedSource<<faac172dcc8ce2e1a558e5631404d742>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpdateDocument_Mutation$variables = {
  externalLink?: string | null | undefined;
  id: string;
  name: string;
};
export type useUpdateDocument_Mutation$data = {
  readonly updateDocument: {
    readonly document: {
      readonly id: string;
      readonly lastActivityOn: any;
      readonly name: string;
    };
  };
};
export type useUpdateDocument_Mutation = {
  response: useUpdateDocument_Mutation$data;
  variables: useUpdateDocument_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "externalLink"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "externalLink",
            "variableName": "externalLink"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateDocumentPayload",
    "kind": "LinkedField",
    "name": "updateDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "document",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastActivityOn",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateDocument_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpdateDocument_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "98c8dfcbfe3aae292064b70135d4cf07",
    "id": null,
    "metadata": {},
    "name": "useUpdateDocument_Mutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateDocument_Mutation(\n  $id: ID!\n  $name: String!\n  $externalLink: String\n) {\n  updateDocument(input: {id: $id, name: $name, externalLink: $externalLink}) {\n    document {\n      id\n      name\n      lastActivityOn\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ed1efef10ad04e10d2e9d088ffad451";

export default node;
