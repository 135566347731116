/**
 * @generated SignedSource<<9b0b99bf52fc39137ea1293fa57b2c9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TableOfContents_Query$data = {
  readonly html: string;
  readonly " $fragmentType": "TableOfContents_Query";
};
export type TableOfContents_Query$key = {
  readonly " $data"?: TableOfContents_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"TableOfContents_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TableOfContents_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "html",
      "storageKey": null
    }
  ],
  "type": "ContentVersion",
  "abstractKey": null
};

(node as any).hash = "092911c5513ba55c3410544843ef4415";

export default node;
