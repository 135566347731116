/**
 * @generated SignedSource<<7a82c7b56b9c80fdfdf1103f89e828a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "ADMIN" | "READ" | "WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserRow_Query$data = {
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly picture: string | null | undefined;
  readonly role: UserRole;
  readonly " $fragmentType": "UserRow_Query";
};
export type UserRow_Query$key = {
  readonly " $data"?: UserRow_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserRow_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserRow_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "picture",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "2fd97f79d7c78f2e803963a21fe6c718";

export default node;
