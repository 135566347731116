/**
 * @generated SignedSource<<2b1fd3cfd1dd398f5caf5fe32fbd21e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VersionList_Query$data = {
  readonly friendlyId: string;
  readonly versions: {
    readonly edges: ReadonlyArray<{
      readonly __id: string;
      readonly node: {
        readonly draft: boolean;
        readonly friendlyId: string;
        readonly id: string;
        readonly lastActivityOn: any;
        readonly notes: string | null | undefined;
      };
    }> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"DiffDialog_Query">;
  } | null | undefined;
  readonly " $fragmentType": "VersionList_Query";
};
export type VersionList_Query$key = {
  readonly " $data"?: VersionList_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"VersionList_Query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VersionList_Query",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VersionsConnection",
      "kind": "LinkedField",
      "name": "versions",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DiffDialog_Query"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VersionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastActivityOn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "draft",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "notes",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};
})();

(node as any).hash = "a07061e7dc85d147e5cfd3818d8c7955";

export default node;
