import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { UserRole } from "./hooks/__generated__/useSetUserRole_Mutation.graphql";

interface Props {
  user?: { name: string; role: UserRole };
  open: boolean;
  onCancel: VoidFunction;
  onSetUserRole: (role: UserRole) => void;
}
export default function SetUserRoleModal({
  user,
  open,
  onCancel,
  onSetUserRole,
}: Props) {
  const roles = ["Read", "Write", "Admin"];
  const [role, setRole] = useState<UserRole | "">("");

  const handleRoleChange = (value: string) => {
    if (
      !value?.length ||
      roles.map((m) => m.toUpperCase()).indexOf(value) === -1
    ) {
      return;
    }

    setRole(value as UserRole);
  };

  const setUserRole = () => {
    if (!role) {
      return;
    }

    onSetUserRole(role);
  };

  const cancel = () => {
    onCancel();
    setRole("");
  };

  useEffect(() => {
    if (user) {
      setRole((role) => (role === "" ? user.role : role));
    }
  }, [user]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={!!user && open}
      onClose={cancel}
      aria-labelledby="invite-user-title"
    >
      <DialogTitle id="invite-user-title">
        Set Role for {user?.name}
      </DialogTitle>
      <DialogContent>
        <Stack marginTop={1}>
          <FormControl size="small">
            <InputLabel id="create-user-role-label">Role</InputLabel>
            <Select
              labelId="create-user-role-label"
              label="Role"
              value={role}
              onChange={(ev) => handleRoleChange(ev.target.value)}
            >
              {roles.map((m) => (
                <MenuItem key={m} value={m.toUpperCase()}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={setUserRole} autoFocus>
          Set Role
        </Button>
      </DialogActions>
    </Dialog>
  );
}
