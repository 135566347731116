import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useContext } from "react";
import { confirmationDialogContext } from "../../ConfirmationDialogContext";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { ProjectCard_Query$key } from "./__generated__/ProjectCard_Query.graphql";
import { useArchiveProject } from "../hooks/useArchiveProject";
import { useDeleteProject } from "../hooks/useDeleteProject";
import { useExportProject } from "../hooks/useExportProject";
import { useRestoreProject } from "../hooks/useRestoreProject";
import { Requires } from "../../UserContext";

interface Props {
  query: ProjectCard_Query$key;
  onEdit: (project: { id: string; name: string; description: string }) => void;
  editing: (id: string) => boolean;
}
export default function ProjectCard({ query, onEdit, editing }: Props) {
  const data = useFragment(
    graphql`
      fragment ProjectCard_Query on Project {
        id
        friendlyId
        name
        description
        archived
      }
    `,
    query
  );

  const { confirm } = useContext(confirmationDialogContext);

  const [archiveProject, archiving] = useArchiveProject();
  const [restoreProject, restoring] = useRestoreProject();
  const [deleteProject, deleting] = useDeleteProject();
  const [exportProject, exporting] = useExportProject();

  return (
    <Card sx={{ width: 250 }}>
      <CardActionArea href={`/${data.friendlyId}`}>
        <CardContent sx={{ height: "5rem" }}>
          {data.archived && (
            <ArchiveIcon color="disabled" sx={{ float: "right", margin: -2 }} />
          )}

          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.name}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {data.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Requires requires="WRITE">
        <>
          <Divider />
          <CardActions>
            <IconButton
              title="Edit"
              onClick={() => onEdit(data)}
              disabled={data.archived || editing(data.id)}
            >
              <EditIcon />
            </IconButton>
            {!data.archived && (
              <IconButton
                title="Archive"
                onClick={() => archiveProject(data.id)}
                disabled={archiving}
              >
                <ArchiveIcon />
              </IconButton>
            )}
            {data.archived && (
              <IconButton
                title="Restore"
                onClick={() => restoreProject(data.id)}
                disabled={restoring}
              >
                <UnarchiveIcon />
              </IconButton>
            )}
            <IconButton
              title="Export"
              onClick={() => exportProject(data.id)}
              disabled={exporting}
            >
              <FileDownloadIcon />
            </IconButton>
            <Requires requires="ADMIN">
              <IconButton
                title="Delete"
                onClick={() =>
                  confirm(
                    "Confirm Delete",
                    `Confirm you want to delete project '${data.name}'.`,
                    "Delete",
                    () => () => deleteProject(data.id)
                  )
                }
                disabled={deleting}
              >
                <DeleteIcon />
              </IconButton>
            </Requires>
          </CardActions>
        </>
      </Requires>
    </Card>
  );
}
