/**
 * @generated SignedSource<<d9d6dd54cd3c33adcf0be543e742ad93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiffPage_Query$variables = {
  diffVersionId: string;
  friendlyDocumentId: string;
  friendlyProjectId: string;
  friendlyVersionId: string;
};
export type DiffPage_Query$data = {
  readonly project: {
    readonly archived: boolean;
    readonly document: {
      readonly diffVersion: {
        readonly content: string | null | undefined;
      } | null | undefined;
      readonly id: string;
      readonly version: {
        readonly content: string | null | undefined;
        readonly id: string;
      } | null | undefined;
      readonly versions: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly draft: boolean;
            readonly id: string;
            readonly lastActivityOn: any;
          };
        }> | null | undefined;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"VersionList_Query">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"DocumentList_Query">;
  };
};
export type DiffPage_Query = {
  response: DiffPage_Query$data;
  variables: DiffPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "diffVersionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "friendlyDocumentId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "friendlyProjectId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "friendlyVersionId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "friendlyId",
    "variableName": "friendlyProjectId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "friendlyId",
    "variableName": "friendlyDocumentId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "friendlyId",
      "variableName": "friendlyVersionId"
    }
  ],
  "concreteType": "ContentVersion",
  "kind": "LinkedField",
  "name": "version",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "friendlyId",
    "variableName": "diffVersionId"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "draft",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastActivityOn",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
},
v14 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DiffPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentList_Query"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VersionList_Query"
              },
              (v9/*: any*/),
              {
                "alias": "diffVersion",
                "args": (v10/*: any*/),
                "concreteType": "ContentVersion",
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VersionsConnection",
                "kind": "LinkedField",
                "name": "versions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VersionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentVersion",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DiffPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentsConnection",
            "kind": "LinkedField",
            "name": "documents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "favorite",
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "externalLink",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VersionsConnection",
                "kind": "LinkedField",
                "name": "versions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VersionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentVersion",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v13/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "notes",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": "diffVersion",
                "args": (v10/*: any*/),
                "concreteType": "ContentVersion",
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b2003a6eef0b5961784f95a7c90a886",
    "id": null,
    "metadata": {},
    "name": "DiffPage_Query",
    "operationKind": "query",
    "text": "query DiffPage_Query(\n  $friendlyProjectId: String!\n  $friendlyDocumentId: String!\n  $friendlyVersionId: String!\n  $diffVersionId: String!\n) {\n  project(friendlyId: $friendlyProjectId) {\n    archived\n    ...DocumentList_Query\n    document(friendlyId: $friendlyDocumentId) {\n      id\n      ...VersionList_Query\n      version(friendlyId: $friendlyVersionId) {\n        id\n        content\n      }\n      diffVersion: version(friendlyId: $diffVersionId) {\n        content\n        id\n      }\n      versions {\n        edges {\n          node {\n            id\n            draft\n            lastActivityOn\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment DiffDialog_Query on VersionsConnection {\n  edges {\n    node {\n      id\n      friendlyId\n      draft\n      lastActivityOn\n    }\n  }\n}\n\nfragment DocumentDialogForm_Query on Document {\n  id\n  name\n  type\n  externalLink\n}\n\nfragment DocumentList_Query on Project {\n  id\n  friendlyId\n  documents {\n    edges {\n      node {\n        id\n        friendlyId\n        favorite\n        lastActivityOn\n        name\n        type\n        externalLink\n        ...DocumentDialogForm_Query\n      }\n    }\n  }\n}\n\nfragment VersionList_Query on Document {\n  friendlyId\n  versions {\n    ...DiffDialog_Query\n    edges {\n      node {\n        id\n        friendlyId\n        lastActivityOn\n        draft\n        notes\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4fe332e3d906c3b87c47cb1e7926b28";

export default node;
