import { List, ListItem, ListItemText } from "@mui/material";
import { headerTreeNode } from "./TableOfContents";
import { random } from "lodash";

export default function HeaderTree({
  node: { header, id, level, children },
}: {
  node: headerTreeNode;
}) {
  const scrollTo = (id?: string) => {
    if (!id) {
      return;
    }

    const el = document.getElementById(id);
    el?.scrollIntoView(true);
  };

  return (
    <List
      dense
      sx={{
        marginY: 0,
        paddingY: 0,
        paddingLeft: level === 1 ? 0 : 2,
      }}
    >
      {header && (
        <ListItem component="div" sx={{ paddingLeft: 0, cursor: "pointer" }}>
          <ListItemText primary={header} onClick={() => scrollTo(id)} />
        </ListItem>
      )}
      {children?.map((m) => (
        <HeaderTree key={m.id || random()} node={m} />
      ))}
    </List>
  );
}
