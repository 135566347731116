/**
 * @generated SignedSource<<97b59cdc6280031ada18b5c7cc71e0c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpdateAnnotation_Mutation$variables = {
  content: string;
  id: string;
};
export type useUpdateAnnotation_Mutation$data = {
  readonly updateAnnotation: {
    readonly annotation: {
      readonly content: string;
      readonly id: string;
      readonly updatedOn: any;
    };
  };
};
export type useUpdateAnnotation_Mutation = {
  response: useUpdateAnnotation_Mutation$data;
  variables: useUpdateAnnotation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "content"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "content",
            "variableName": "content"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateAnnotationPayload",
    "kind": "LinkedField",
    "name": "updateAnnotation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Annotation",
        "kind": "LinkedField",
        "name": "annotation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedOn",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateAnnotation_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpdateAnnotation_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "429d8f7447b4bdb95f525687f9c69994",
    "id": null,
    "metadata": {},
    "name": "useUpdateAnnotation_Mutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateAnnotation_Mutation(\n  $id: ID!\n  $content: String!\n) {\n  updateAnnotation(input: {id: $id, content: $content}) {\n    annotation {\n      id\n      content\n      updatedOn\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4d58632e447271629b43d251bf55cbf8";

export default node;
