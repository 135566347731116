import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

export const useDeleteAnnotation = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useDeleteAnnotation_Mutation($id: ID!) {
      deleteAnnotation(input: { id: $id }) {
        annotation {
          id
          content
          replies {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  `);

  const func = (id: string) => {
    mutation({
      variables: {
        id,
      },
      updater: (storeProxy, data) => {
        // we can't use the @deleteRecord attribute because
        // we need to display the annotation still if there are any replies
        const response = data as {
          deleteAnnotation: {
            annotation: { content: string; replies: { edges: [] } };
          };
        };

        if (!response.deleteAnnotation.annotation) {
          return;
        }

        if (response.deleteAnnotation.annotation.replies.edges.length === 0) {
          storeProxy.delete(id);
        } else {
          storeProxy
            .get(id)
            ?.setValue(response.deleteAnnotation.annotation.content, "content");
        }
      },
    });
  };

  return [func, inFlight] as [(id: string) => void, boolean];
};
