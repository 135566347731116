/**
 * @generated SignedSource<<49e7d65fbe746daa1a2e769cf805f9cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnnotationContent_Query$data = {
  readonly content: string;
  readonly createdBy: {
    readonly id: string;
    readonly name: string;
  };
  readonly deleted: boolean;
  readonly id: string;
  readonly selectionText: string | null | undefined;
  readonly updatedOn: any;
  readonly " $fragmentType": "AnnotationContent_Query";
};
export type AnnotationContent_Query$key = {
  readonly " $data"?: AnnotationContent_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnnotationContent_Query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnnotationContent_Query",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectionText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Annotation",
  "abstractKey": null
};
})();

(node as any).hash = "1875f270bb87382431c7cb8b29795bdf";

export default node;
