import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useCreateVersion_Mutation$variables } from "./__generated__/useCreateVersion_Mutation.graphql";

export const useCreateVersion = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useCreateVersion_Mutation(
      $documentId: ID!
      $basedOn: ID
      $connections: [ID!]!
    ) {
      createVersion(
        input: { documentId: $documentId, basedOn: $basedOn }
      ) {
        version
          @appendNode(connections: $connections, edgeTypeName: "VersionsEdge") {
          id
          friendlyId
          content
          draft
          html
          notes
          documentId
          lastActivityOn
        }
      }
    }
  `);

  const func = (
    variables: useCreateVersion_Mutation$variables,
    onCompleted?: (response: {}) => void
  ) => {
    mutation({
      variables,
      onCompleted,
    });
  };

  return [func, inFlight] as [
    (
      variables: useCreateVersion_Mutation$variables,
      onCompleted?: (response: {}) => void
    ) => void,
    boolean
  ];
};
