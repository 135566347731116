import { Box, Button } from "@mui/material";
import mermaid from "mermaid";
import { useLayoutEffect } from "react";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { MermaidViewer_Query$key } from "./__generated__/MermaidViewer_Query.graphql";
import { Requires } from "../../UserContext";

interface Props {
  query?: MermaidViewer_Query$key | null;
  readOnly: boolean;
  showEdit: boolean;
  setEdit: VoidFunction;
}
export default function MermaidViewer({
  query,
  readOnly,
  showEdit,
  setEdit,
}: Props) {
  const data = useFragment(
    graphql`
      fragment MermaidViewer_Query on ContentVersion {
        content
      }
    `,
    query
  );

  useLayoutEffect(() => {
    if (data?.content) {
      mermaid.run({
        querySelector: ".mermaid",
      });
    }
  }, [data?.content]);

  return (
    <Box
      className="MermaidViewer"
      flexGrow={1}
      sx={{ overflow: "auto", paddingRight: 1 }}
    >
      {showEdit && (
        <Requires requires="WRITE">
          <Button
            onClick={setEdit}
            variant="contained"
            sx={{
              marginRight: 2,
              marginBottom: -5,
              float: "right",
              position: "sticky",
              top: 0,
            }}
          >
            Edit
          </Button>
        </Requires>
      )}
      {data && <pre className="mermaid">{data.content}</pre>}
    </Box>
  );
}
