import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

export const useRestoreProject = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useRestoreProject_Mutation($id: ID!) {
      restoreProject(input: { id: $id }) {
        project {
          id
          archived
          lastActivityOn
        }
      }
    }
  `);

  const func = (id: string) => {
    mutation({
      variables: {
        id,
      },
    });
  };

  return [func, inFlight] as [(id: string) => void, boolean];
};
