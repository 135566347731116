import { Button, Stack, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { AnnotationMenu } from "./AnnotationMenu";
import RenderWithLineBreaks from "./RenderWithLineBreaks";
import { useDeleteAnnotation } from "../../hooks/annotation/useDeleteAnnotation";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { AnnotationContent_Query$key } from "./__generated__/AnnotationContent_Query.graphql";
import { useState } from "react";
import { useUpdateAnnotation } from "../../hooks/annotation/useUpdateAnnotation";
import { Requires, useUser } from "../../../UserContext";

interface Props {
  query: AnnotationContent_Query$key;
}
export default function AnnotationContent({ query }: Props) {
  const data = useFragment(
    graphql`
      fragment AnnotationContent_Query on Annotation {
        id
        selectionText
        content
        deleted
        updatedOn
        createdBy {
          id
          name
        }
      }
    `,
    query
  );

  const user = useUser();

  const [editingAnnotation, setEditingAnnotation] = useState(false);
  const [content, setContent] = useState(data.content);
  const [updateAnnotation, updating] = useUpdateAnnotation();
  const [deleteAnnotation, deleting] = useDeleteAnnotation();

  return (
    <Stack
      sx={{
        ["&:hover > .AnnotationMenu"]: {
          display: "block",
        },
      }}
    >
      {!data.deleted && !editingAnnotation && (
        <Requires
          requires="ADMIN"
          or={() => user.user?.id === data.createdBy.id}
        >
          <AnnotationMenu
            text={data.content}
            createdBy={data.createdBy.id}
            onEdit={() => setEditingAnnotation(true)}
            onDelete={() => deleteAnnotation(data.id)}
            editing={updating}
            deleting={deleting}
          />
        </Requires>
      )}
      <Stack direction="row">
        <Typography
          flexGrow={1}
          component="span"
          variant="body1"
          color="text.primary"
        >
          {data.createdBy.name}
        </Typography>
        <Typography component="span" variant="body2" color="text.secondary">
          {DateTime.fromISO(data.updatedOn).toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
      </Stack>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          paddingRight: 1,
          overflow: "auto",
          maxHeight: "4rem",
        }}
      >
        <RenderWithLineBreaks text={data.selectionText ?? ""} />
      </Typography>
      {!editingAnnotation && (
        <Typography
          variant="body1"
          color="text.primary"
          sx={{
            paddingRight: 1,
            overflow: "auto",
            maxHeight: "4.5rem",
          }}
        >
          <RenderWithLineBreaks text={data.content} />
        </Typography>
      )}
      {editingAnnotation && (
        <Stack>
          <TextField
            autoFocus
            fullWidth
            multiline
            required
            label="Content"
            variant="standard"
            value={content}
            onChange={(ev) => setContent(ev.target.value)}
          />
          <Stack direction="row" alignSelf="end">
            <Button
              variant="text"
              size="small"
              sx={{ minWidth: "unset" }}
              onClick={() => {
                setEditingAnnotation(false);
                setContent(data.content);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              size="small"
              sx={{ minWidth: "unset", marginRight: -0.5 }}
              disabled={!content}
              onClick={() => {
                setEditingAnnotation(false);
                updateAnnotation(data.id, content);
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
