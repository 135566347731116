import Sidebar from "./Sidebar";
import TopicIcon from '@mui/icons-material/Topic';
import TocIcon from "@mui/icons-material/Toc";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { sidebarContext } from "../Contexts/SidebarContext";

export default function LeftSidebar({
  displayButtons,
}: {
  displayButtons: ("Table of Contents" | "Documents" | "Search")[];
}) {
  const { leftSidebarPane, setLeftSidebarPane } = useContext(sidebarContext);

  return (
    <Sidebar>
      <ToggleButtonGroup
        color="primary"
        orientation="vertical"
        value={leftSidebarPane}
        exclusive
        onChange={(
          ev: React.MouseEvent<HTMLElement>,
          newView: "Table of Contents" | "Documents"
        ) => setLeftSidebarPane(newView ?? "None")}
        sx={{
          "& .MuiToggleButtonGroup-grouped": {
            margin: 0,
            border: 0,
            borderRadius: 0,
            padding: 0,
            "&.Mui-selected": {
              borderRight: "2px solid",
            },
          },
        }}
      >
        {displayButtons.includes("Documents") && (
          <ToggleButton value="Documents" aria-label="Documents">
            <Tooltip
              placement="right"
              title="Documents"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: ".9rem",
                    color: "text.primary",
                    backgroundColor: "background.paper",
                    border: "1px solid",
                  },
                },
              }}
              arrow
            >
              {<TopicIcon sx={{ padding: 2 }} />}
            </Tooltip>
          </ToggleButton>
        )}
        {displayButtons.includes("Table of Contents") && (
          <ToggleButton
            value="Table of Contents"
            aria-label="Table of Contents"
          >
            <Tooltip
              placement="right"
              title="Table of Contents"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: ".9rem",
                    color: "text.primary",
                    backgroundColor: "background.paper",
                    border: "1px solid",
                  },
                },
              }}
              arrow
            >
              {<TocIcon sx={{ padding: 2 }} />}
            </Tooltip>
          </ToggleButton>
        )}
        {displayButtons.includes("Search") && (
          <ToggleButton
            value="Search"
            aria-label="Search"
          >
            <Tooltip
              placement="right"
              title="Search"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: ".9rem",
                    color: "text.primary",
                    backgroundColor: "background.paper",
                    border: "1px solid",
                  },
                },
              }}
              arrow
            >
              {<SearchIcon sx={{ padding: 2 }} />}
            </Tooltip>
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Sidebar>
  );
}
