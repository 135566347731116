import { useParams } from "react-router-dom";
import Diff from "./Diff";
import { Stack, Divider } from "@mui/material";
import DocumentList from "../sidebar-panels/DocumentList/DocumentList";
import LeftSidebar from "../Sidebars/LeftSidebar";
import RightSidebar from "../Sidebars/RightSidebar";
import VersionList from "../sidebar-panels/VersionList/VersionList";
import { QueryRenderer, useRelayEnvironment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  DiffPage_Query,
  DiffPage_Query$data,
  DiffPage_Query$variables,
} from "./__generated__/DiffPage_Query.graphql";
import { isProjectReadOnly } from "../utilities/utilities";
import Search from "../sidebar-panels/Search/Search";

export default function DiffPage() {
  const environment = useRelayEnvironment();

  const query = graphql`
    query DiffPage_Query(
      $friendlyProjectId: String!
      $friendlyDocumentId: String!
      $friendlyVersionId: String!
      $diffVersionId: String!
    ) {
      project(friendlyId: $friendlyProjectId) {
        archived
        ...DocumentList_Query
        document(friendlyId: $friendlyDocumentId) {
          id
          ...VersionList_Query
          version: version(friendlyId: $friendlyVersionId) {
            id
            content
          }
          diffVersion: version(friendlyId: $diffVersionId) {
            content
          }
          versions {
            edges {
              node {
                id
                draft
                lastActivityOn
              }
            }
          }
        }
      }
    }
  `;

  const { projectId, documentId, versionId, diffVersionId } = useParams();

  return (
    <QueryRenderer<DiffPage_Query>
      environment={environment}
      query={query}
      render={({
        error,
        props,
      }: {
        error: Error | null;
        props: DiffPage_Query$data | null;
      }) => (
        <Stack
          className="DiffView"
          useFlexGap
          flexGrow={1}
          direction="row"
          gap={2}
          sx={{ overflow: "hidden" }}
        >
          <LeftSidebar displayButtons={["Documents", "Search"]} />
          <DocumentList
            query={props?.project}
            activeDocumentId={props?.project.document?.id}
            readOnly={isProjectReadOnly(props?.project)}
          />
          <Search />
          <Divider flexItem variant="middle" orientation="vertical" />
          <Stack flexGrow={1} sx={{ overflow: "auto" }}>
            {props?.project.document?.version?.content &&
              props?.project.document?.diffVersion?.content && (
                <Diff
                  str1={props.project.document.version.content}
                  str2={props.project.document.diffVersion.content}
                />
              )}
          </Stack>
          <Divider flexItem variant="middle" orientation="vertical" />
          <VersionList
            query={props?.project.document}
            activeVersionId={props?.project.document?.version?.id}
            readOnly={isProjectReadOnly(props?.project)}
          />
          <RightSidebar displayButtons={["Versions"]} />
        </Stack>
      )}
      variables={
        {
          friendlyProjectId: projectId,
          friendlyDocumentId: documentId,
          friendlyVersionId: versionId,
          diffVersionId: diffVersionId,
        } as DiffPage_Query$variables
      }
    />
  );
}
