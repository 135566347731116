/**
 * @generated SignedSource<<77d44ffa95054ae651c50898d79512b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDeleteDocument_Mutation$variables = {
  id: string;
};
export type useDeleteDocument_Mutation$data = {
  readonly deleteDocument: {
    readonly document: {
      readonly id: string;
    };
  };
};
export type useDeleteDocument_Mutation = {
  response: useDeleteDocument_Mutation$data;
  variables: useDeleteDocument_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteDocument_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteDocumentPayload",
        "kind": "LinkedField",
        "name": "deleteDocument",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteDocument_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteDocumentPayload",
        "kind": "LinkedField",
        "name": "deleteDocument",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16a9b98ffdf187a15851f2e2f2beba1d",
    "id": null,
    "metadata": {},
    "name": "useDeleteDocument_Mutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteDocument_Mutation(\n  $id: ID!\n) {\n  deleteDocument(input: {id: $id}) {\n    document {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0c014956bef6b3847d58f051ea30596";

export default node;
