/**
 * @generated SignedSource<<e92af2c4176ef7af36e5a6e9527a4b56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentEditor_Query$data = {
  readonly content: string | null | undefined;
  readonly html: string;
  readonly id: string;
  readonly " $fragmentType": "DocumentEditor_Query";
};
export type DocumentEditor_Query$key = {
  readonly " $data"?: DocumentEditor_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentEditor_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentEditor_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "html",
      "storageKey": null
    }
  ],
  "type": "ContentVersion",
  "abstractKey": null
};

(node as any).hash = "cd71514ce72d81c6dce9fd9b8958f226";

export default node;
