/**
 * @generated SignedSource<<75a232d524224b364c6e7f5855c82b23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchResultType = "ANNOTATION" | "DOCUMENT" | "VERSION" | "%future added value";
export type SearchInput = {
  filter: string;
  friendlyProjectId: string;
};
export type Search_Query$variables = {
  filter: SearchInput;
};
export type Search_Query$data = {
  readonly search: {
    readonly results: ReadonlyArray<{
      readonly annotationId: string | null | undefined;
      readonly documentId: string;
      readonly highlightRange: ReadonlyArray<{
        readonly length: number;
        readonly sourcePosition: number;
      }>;
      readonly text: string;
      readonly type: SearchResultType;
      readonly versionId: string | null | undefined;
    }>;
  };
};
export type Search_Query = {
  response: Search_Query$data;
  variables: Search_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "SearchPayload",
    "kind": "LinkedField",
    "name": "search",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SearchResult",
        "kind": "LinkedField",
        "name": "results",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "documentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "versionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "annotationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchResultHighlight",
            "kind": "LinkedField",
            "name": "highlightRange",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sourcePosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "length",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Search_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Search_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "88f14bfd0f20656411810552377e2519",
    "id": null,
    "metadata": {},
    "name": "Search_Query",
    "operationKind": "query",
    "text": "query Search_Query(\n  $filter: SearchInput!\n) {\n  search(filter: $filter) {\n    results {\n      documentId\n      versionId\n      annotationId\n      type\n      text\n      highlightRange {\n        sourcePosition\n        length\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c61ff2144815df9f231d1af7f00c03e";

export default node;
