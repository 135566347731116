import { useState, createContext, ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export type ConfirmationDialogContextType = {
  confirm: (
    title: string,
    description: string,
    actionLabel: string,
    callback?: VoidFunction
  ) => void;
};

const notImplemented = () => {
  throw new Error("Not Implemented");
};

export const confirmationDialogContext = createContext<ConfirmationDialogContextType>({
  confirm: notImplemented,
});

export default function ConfirmationDialogContext({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [actionLabel, setActionLabel] = useState("");
  const [callback, setCallback] = useState<VoidFunction>();

  const openModal = (
    title: string,
    description: string,
    actionLabel: string,
    callback?: VoidFunction
  ) => {
    setTitle(title);
    setDescription(description);
    setActionLabel(actionLabel);
    setCallback(callback);

    setOpen(true)
  };

  return (
    <confirmationDialogContext.Provider
      value={{
        confirm: openModal,
      }}
    >
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              callback?.();
              setOpen(false);
            }}
            autoFocus
          >
            {actionLabel}
          </Button>
        </DialogActions>
      </Dialog>

      {children}
    </confirmationDialogContext.Provider>
  );
}
