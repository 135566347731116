import { ThemeOptions } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#020240",
    },
    secondary: {
      main: "#404002",
    },
  },
};

export const acmeThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#7c9447",
    },
    secondary: {
      main: "#1059e6",
    },
  },
  typography: {
    fontFamily: "Consolas monospace",
    fontSize: 14,
    htmlFontSize: 12,
  }
};
