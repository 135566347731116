import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { DocumentHeader_Query$key } from "./__generated__/DocumentHeader_Query.graphql";
import { useState } from "react";

export interface Props {
  query?: DocumentHeader_Query$key | null;
}
export default function DocumentHeader({ query }: Props) {
  const data = useFragment(
    graphql`
      fragment DocumentHeader_Query on Project {
        name
        description
      }
    `,
    query
  );

  const [open, setOpen] = useState(false);

  return (
    <Stack ml={{md: 2, xs: 1}} direction={{ md: "row", sm: "column" }}>
      <Stack
        direction={{ lg: "row", md: "column" }}
        spacing={{ lg: 2 }}
        flexGrow={1}
      >
        <Typography
          variant="h3"
          component="h2"
          title={data?.name}
          sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          {data?.name}
        </Typography>
        <Stack direction="row" sx={{ alignSelf: { lg: "center" } }}>
          <Typography
            variant="body2"
            color="text.secondary"
            component="span"
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {data?.description.substring(0, 75)}
            {data?.description && data?.description?.length > 75 ? "…" : ""}
          </Typography>
          {data?.description && data?.description?.length > 75 && (
            <Button
              variant="text"
              sx={{ flexShrink: 0, paddingY: 0 }}
              onClick={() => setOpen(true)}
            >
              View More
            </Button>
          )}
        </Stack>
      </Stack>

      <Typography sx={{ textAlign: "end" }}>
        <Button href="/projects" sx={{ flexShrink: 0 }}>
          <NavigateBeforeIcon />
          Back to Projects
        </Button>
      </Typography>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="header-dialog-title"
        aria-describedby="header-dialog-description"
      >
        <DialogTitle id="header-dialog-title">Description</DialogTitle>
        <DialogContent>
          <DialogContentText id="header-dialog-description" overflow="auto">
            {data?.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}