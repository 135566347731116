import { Box } from "@mui/material";

export default function MarkdownRender({
  html,
  onMouseUp,
}: {
  html?: string | null;
  onMouseUp?: (event: React.MouseEvent<HTMLElement>) => void;
}) {
  return (
    <Box
      onMouseUp={onMouseUp}
      dangerouslySetInnerHTML={{ __html: html ?? "" }}
      sx={{
        code: {
          backgroundColor: "grey.300",
          padding: 0.25,
          borderRadius: 0.5,
        },
        pre: {
          backgroundColor: "grey.300",
          padding: 0.25,
          borderRadius: 0.5,
        },
        blockquote: {
          borderLeft: "1px solid",
          borderLeftColor: "divider",
          padding: 0.25,
          paddingLeft: 3,
          margin: "unset",
        },
        hr: {
          border: 0,
          borderBottom: "1px solid",
        },
        "blockquote p": {
          marginY: 1,
        },
        "ul,ol": {
          paddingLeft: 2,
        },
      }}
    ></Box>
  );
}
