/**
 * @generated SignedSource<<d1011a504ead622f9ea2b6607565feac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDeleteVersion_Mutation$variables = {
  id: string;
};
export type useDeleteVersion_Mutation$data = {
  readonly deleteVersion: {
    readonly version: {
      readonly id: string;
    };
  };
};
export type useDeleteVersion_Mutation = {
  response: useDeleteVersion_Mutation$data;
  variables: useDeleteVersion_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteVersion_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteContentVersionPayload",
        "kind": "LinkedField",
        "name": "deleteVersion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentVersion",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteVersion_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteContentVersionPayload",
        "kind": "LinkedField",
        "name": "deleteVersion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentVersion",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78ad5103a28769f62b978b3797f161cf",
    "id": null,
    "metadata": {},
    "name": "useDeleteVersion_Mutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteVersion_Mutation(\n  $id: ID!\n) {\n  deleteVersion(input: {id: $id}) {\n    version {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "52121bc48ecbb550023b1ec8af2e4ba4";

export default node;
