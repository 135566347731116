import { Button } from "@mui/material";
import Header from "../../Header";
import { Requires } from "../../UserContext";

export default function ProjectListHeader({
  onCreateProject,
  creating,
}: {
  onCreateProject: VoidFunction;
  creating: boolean;
}) {
  return (
    <Header
      header="Projects"
      action={
        <Requires requires="WRITE">
          <Button
            variant="contained"
            onClick={onCreateProject}
            disabled={creating}
          >
            Create Project
          </Button>
        </Requires>
      }
    />
  );
}
