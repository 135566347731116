import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useCreateUser_Mutation$variables } from "./__generated__/useCreateUser_Mutation.graphql";

export const useCreateUser = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useCreateUser_Mutation(
      $name: String!
      $email: String!
      $role: UserRole!
      $connections: [ID!]!
    ) {
      createUser(input: { name: $name, email: $email, role: $role }) {
        user @appendNode(connections: $connections, edgeTypeName: "UsersEdge") {
          id
          externalId
          name
          email
          picture
          role
        }
      }
    }
  `);

  const func = (variables: useCreateUser_Mutation$variables) => {
    mutation({
      variables,
    });
  };

  return [func, inFlight] as [
    (variables: useCreateUser_Mutation$variables) => void,
    boolean
  ];
};
