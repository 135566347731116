import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

export default function AnnotationDialogForm({
  value,
  open,
  onCancel,
  onSave,
}: {
  value?: string;
  open: boolean;
  onCancel: VoidFunction;
  onSave: (name: string) => void;
}) {
  const defaultContentState = {
    value: "",
    error: false,
    errorMessage: "",
  };

  const [content, setContent] = useState(defaultContentState);

  const handleContentChange = (value: string) => {
    if (!value?.length) {
      setContent({
        value,
        error: true,
        errorMessage: "Annotation is required",
      });
    } else {
      setContent({ value, error: false, errorMessage: "" });
    }
  };

  const cancel = () => {
    onCancel();
    setContent(defaultContentState);
  };

  const save = () => {
    onSave(content.value);
    setContent(defaultContentState);
  };

  useEffect(() => {
    if (value) {
      handleContentChange(value);
    }
  }, [value]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={cancel}
      aria-labelledby="annotation-dialog-title"
    >
      <DialogTitle id="annotation-dialog-title">Create Annotation</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          autoFocus
          fullWidth
          required
          label="Annotation"
          variant="standard"
          value={content.value}
          error={content.error}
          helperText={content.errorMessage}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleContentChange(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={save} variant="contained" disabled={content.error}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
