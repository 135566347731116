import { Divider, Stack } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import Annotations from "../sidebar-panels/Annotations/Annotations";
import MarkdownViewer from "../MarkdownViewer/MarkdownViewer";
import DocumentList from "../sidebar-panels/DocumentList/DocumentList";
import LeftSidebar from "../Sidebars/LeftSidebar";
import RightSidebar from "../Sidebars/RightSidebar";
import TableOfContents from "../sidebar-panels/TableOfContents/TableOfContents";
import VersionList from "../sidebar-panels/VersionList/VersionList";
import EmbeddedResource from "../EmbeddedResource/EmbeddedResource";
import MermaidViewer from "../MermaidViewer/MermaidViewer";
import { QueryRenderer, useRelayEnvironment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  ReadDocumentPage_Query,
  ReadDocumentPage_Query$data,
  ReadDocumentPage_Query$variables,
} from "./__generated__/ReadDocumentPage_Query.graphql";
import { useCreateVersion } from "../hooks/versions/useCreateVersion";
import { isProjectReadOnly } from "../utilities/utilities";
import { useCreateAnnotation } from "../hooks/annotation/useCreateAnnotation";
import { useCreateAnnotation_Mutation$variables } from "../hooks/annotation/__generated__/useCreateAnnotation_Mutation.graphql";
import FigmaResource from "../EmbeddedResource/FigmaResource";
import Search from "../sidebar-panels/Search/Search";

export default function ReadDocumentPage() {
  const environment = useRelayEnvironment();

  const query = graphql`
    query ReadDocumentPage_Query(
      $friendlyProjectId: String!
      $friendlyDocumentId: String
      $friendlyVersionId: String
    ) {
      project(friendlyId: $friendlyProjectId) {
        ...DocumentList_Query
        archived
        document(friendlyId: $friendlyDocumentId) {
          id
          friendlyId
          type
          ...FigmaResource_Query
          ...EmbeddedResource_Query
          ...VersionList_Query
          versions {
            __id
            edges {
              node {
                id
                draft
                lastActivityOn
              }
            }
          }
          version: version(friendlyId: $friendlyVersionId) {
            id
            friendlyId
            draft
            content
            html
            documentId
            ...TableOfContents_Query
            ...MarkdownViewer_Query
            ...MermaidViewer_Query
            annotations {
              __id
              ...Annotations_Query
            }
          }
          currentVersion: version {
            id
          }
        }
      }
    }
  `;

  const { projectId, documentId, versionId } = useParams();
  const navigate = useNavigate();

  const [createVersion] = useCreateVersion();
  const [createAnnotation] = useCreateAnnotation();

  const canEdit = (props?: ReadDocumentPage_Query$data | null) => {
    return (
      props?.project?.archived === false &&
      (!props?.project.document?.version ||
        props.project.document.version.draft === true ||
        (!props.project.document.versions?.edges?.find((f) => f.node?.draft) &&
          props.project.document.version.id ===
            props.project.document.currentVersion?.id))
    );
  };

  const setEdit =
    (
      document?: {
        id: string;
        friendlyId: string;
        versions: { __id: string } | null | undefined;
      } | null,
      existingDraftVersionCount?: number | null,
      activeVersion?: {
        id: string;
        friendlyId: string;
        draft: boolean;
      } | null
    ) =>
    () => {
      if (!document) {
        return;
      }

      if (!activeVersion || existingDraftVersionCount === 0) {
        createVersion(
          {
            documentId: document.id,
            basedOn: activeVersion?.id,
            connections: document.versions ? [document.versions.__id] : [],
          },
          (response) => {
            const {
              createVersion: {
                version: { friendlyId },
              },
            } = response as {
              createVersion: { version: { friendlyId: string } };
            };

            navigate(
              `/${projectId}/doc/${document.friendlyId}/${friendlyId}/edit`
            );
          }
        );
      } else if (activeVersion.draft) {
        navigate(
          `/${projectId}/doc/${document.friendlyId}/${activeVersion.friendlyId}/edit`
        );
      }
    };

  const createAnnotationHandler =
    (connectionId?: string | null) =>
    (
      variables: Omit<useCreateAnnotation_Mutation$variables, "connections">
    ) => {
      createAnnotation({
        ...variables,
        connections: connectionId ? [connectionId] : [],
      });
    };

  return (
    <QueryRenderer<ReadDocumentPage_Query>
      environment={environment}
      query={query}
      render={({
        error,
        props,
      }: {
        error: Error | null;
        props: ReadDocumentPage_Query$data | null;
      }) => (
        <Stack
          className="ReadDocumentView"
          useFlexGap
          flexGrow={1}
          direction="row"
          gap={1}
          sx={{ overflow: "hidden" }}
        >
          <LeftSidebar
            displayButtons={
              props?.project.document?.type !== "MARKDOWN"
                ? ["Documents", "Search"]
                : ["Table of Contents", "Documents", "Search"]
            }
          />
          <DocumentList
            query={props?.project}
            activeDocumentId={props?.project.document?.id}
            readOnly={isProjectReadOnly(props?.project)}
          />
          {props?.project.document?.type === "MARKDOWN" && (
            <TableOfContents query={props.project.document.version} />
          )}
          <Search />
          <Divider flexItem variant="middle" orientation="vertical" />
          <Stack flexGrow={1}>
            {props?.project.document &&
              (!props.project.document.version ||
                props.project.document.type === "MARKDOWN") && (
                <MarkdownViewer
                  query={props.project.document.version}
                  readOnly={isProjectReadOnly(props?.project)}
                  showEdit={canEdit(props)}
                  setEdit={setEdit(
                    props.project.document,
                    props.project.document.versions?.edges?.filter(
                      (f) => f.node?.draft
                    )?.length,
                    props.project.document.version
                  )}
                  createAnnotation={createAnnotationHandler(
                    props.project.document.version?.annotations?.__id
                  )}
                />
              )}
            {props?.project.document?.version &&
              props.project.document.type === "MERMAID" && (
                <MermaidViewer
                  query={props.project.document.version}
                  readOnly={isProjectReadOnly(props?.project)}
                  showEdit={canEdit(props)}
                  setEdit={setEdit(
                    props.project.document,
                    props.project.document.versions?.edges?.filter(
                      (f) => f.node?.draft
                    )?.length,
                    props.project.document.version
                  )}
                />
              )}
            {props?.project.document?.type === "FIGMA" && (
              <FigmaResource query={props.project.document} />
            )}
            {props?.project.document?.type === "PDF" && (
              <EmbeddedResource query={props.project.document} />
            )}
          </Stack>
          <Divider flexItem variant="middle" orientation="vertical" />
          {(!props?.project.document ||
            props?.project.document?.type === "MARKDOWN" ||
            props?.project.document?.type === "MERMAID") && (
            <VersionList
              query={props?.project.document}
              activeVersionId={props?.project.document?.version?.id}
              readOnly={isProjectReadOnly(props?.project)}
            />
          )}
          {(props?.project.document?.type === "MARKDOWN" ||
            props?.project.document?.type === "MERMAID") && (
            <Annotations
              query={props.project.document.version?.annotations}
              readOnly={isProjectReadOnly(props?.project)}
              activeVersionId={props.project.document.version?.id}
            />
          )}
          <RightSidebar
            displayButtons={
              props?.project.document?.type === "MARKDOWN" ||
              props?.project.document?.type === "MERMAID"
                ? ["Annotations", "Versions"]
                : []
            }
          />
        </Stack>
      )}
      variables={
        {
          friendlyProjectId: projectId,
          friendlyDocumentId: documentId,
          friendlyVersionId: versionId,
        } as ReadDocumentPage_Query$variables
      }
    />
  );
}
