import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { FigmaResource_Query$key } from "./__generated__/FigmaResource_Query.graphql";

interface Props {
  query: FigmaResource_Query$key;
}
export default function FigmaResource({ query }: Props) {
  const data = useFragment(
    graphql`
      fragment FigmaResource_Query on Document {
        name
        externalLink
      }
    `,
    query
  );

  const figmaLink = `https://www.figma.com/embed?embed_host=amethyst-docs&url=${data.externalLink}`

  return (
    <iframe
      title={data.name}
      height="100%"
      width="100%"
      src={figmaLink}
    />
  );
}
