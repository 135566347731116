/**
 * @generated SignedSource<<33bff0dd50a8318d718fb7143ced937b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectRoot_Query$variables = Record<PropertyKey, never>;
export type ProjectRoot_Query$data = {
  readonly projects: {
    readonly __id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectList_Query">;
  } | null | undefined;
};
export type ProjectRoot_Query = {
  response: ProjectRoot_Query$data;
  variables: ProjectRoot_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectsConnection",
        "kind": "LinkedField",
        "name": "projects",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectList_Query"
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectsConnection",
        "kind": "LinkedField",
        "name": "projects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastActivityOn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "friendlyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0527300fe464f3c3de1661d43e72dcd4",
    "id": null,
    "metadata": {},
    "name": "ProjectRoot_Query",
    "operationKind": "query",
    "text": "query ProjectRoot_Query {\n  projects {\n    ...ProjectList_Query\n  }\n}\n\nfragment ProjectCard_Query on Project {\n  id\n  friendlyId\n  name\n  description\n  archived\n}\n\nfragment ProjectList_Query on ProjectsConnection {\n  edges {\n    node {\n      id\n      archived\n      lastActivityOn\n      ...ProjectCard_Query\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "efbc681b403d99d30fe0bdcf46aaf400";

export default node;
