/**
 * @generated SignedSource<<242b86b3cf8e9a969eb5be5072ec0c20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Annotations_Query$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly lastActivityOn: any;
      readonly replyTo: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"Annotation_Query">;
    };
  }> | null | undefined;
  readonly " $fragmentType": "Annotations_Query";
};
export type Annotations_Query$key = {
  readonly " $data"?: Annotations_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"Annotations_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Annotations_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AnnotationsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Annotation",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastActivityOn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "replyTo",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Annotation_Query"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "AnnotationsConnection",
  "abstractKey": null
};

(node as any).hash = "c3527ec3ab6d31d531d743deea2abdfa";

export default node;
