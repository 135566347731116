import { Stack } from "@mui/material";
import { ReactNode } from "react";

export default function SidebarPane({ children }: { children: ReactNode }) {
  return (
    <Stack
      useFlexGap
      direction="column"
      gap={0.5}
      paddingY={1}
      className="Sidebar"
    >
      {children}
    </Stack>
  );
}
