/**
 * @generated SignedSource<<f0f814b56f6c44d0018e1ee366e04418>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpdateProject_Mutation$variables = {
  description: string;
  id: string;
  name: string;
};
export type useUpdateProject_Mutation$data = {
  readonly updateProject: {
    readonly project: {
      readonly archived: boolean;
      readonly description: string;
      readonly id: string;
      readonly lastActivityOn: any;
      readonly name: string;
    };
  };
};
export type useUpdateProject_Mutation = {
  response: useUpdateProject_Mutation$data;
  variables: useUpdateProject_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "description",
            "variableName": "description"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateProjectPayload",
    "kind": "LinkedField",
    "name": "updateProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastActivityOn",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateProject_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpdateProject_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c989f5d9ad0049719d5416918e94ba01",
    "id": null,
    "metadata": {},
    "name": "useUpdateProject_Mutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateProject_Mutation(\n  $id: ID!\n  $name: String!\n  $description: String!\n) {\n  updateProject(input: {id: $id, name: $name, description: $description}) {\n    project {\n      id\n      name\n      description\n      archived\n      lastActivityOn\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2b68af77c8fa351ccfe9495a1a8dc79";

export default node;
