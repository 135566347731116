import { Box, Button, ButtonGroup } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext } from "react";
import { confirmationDialogContext } from "../../../ConfirmationDialogContext";
import { useUser } from "../../../UserContext";

export function AnnotationMenu({
  text,
  createdBy,
  onEdit,
  onDelete,
  editing,
  deleting,
}: {
  text: string;
  createdBy: string;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  editing: boolean;
  deleting: boolean;
}) {
  const { confirm } = useContext(confirmationDialogContext);
  const user = useUser();

  return (
    <Box
      className="AnnotationMenu"
      sx={{
        display: "none",
        position: "absolute",
        right: 0,
        top: 0,
        backgroundColor: "background.paper",
      }}
    >
      <ButtonGroup
        variant="outlined"
        aria-label="text button group"
        color="secondary"
      >
        {user.user?.id === createdBy && (
          <Button title="Edit" onClick={onEdit} disabled={editing}>
            <EditIcon />
          </Button>
        )}
        <Button
          title="Delete"
          onClick={() =>
            confirm(
              "Confirm Delete",
              `Confirm you want to delete annotation '${text.substring(0, 50)}${
                text.length > 50 ? "…" : ""
              }'.`,
              "Delete",
              () => onDelete
            )
          }
          disabled={deleting}
        >
          <DeleteIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
}
