import { Box, Stack } from "@mui/material";
import SidebarContext from "./Contexts/SidebarContext";
import { Outlet, useParams } from "react-router-dom";
import DocumentHeader from "./DocumentHeader";
import { QueryRenderer, useRelayEnvironment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  DocumentRoot_Query,
  DocumentRoot_Query$data,
  DocumentRoot_Query$variables,
} from "./__generated__/DocumentRoot_Query.graphql";

export default function DocumentRoot() {
  const environment = useRelayEnvironment();

  const query = graphql`
    query DocumentRoot_Query($friendlyId: String!) {
      project(friendlyId: $friendlyId) {
        ...DocumentHeader_Query
      }
    }
  `;

  const { projectId } = useParams();

  return (
    <Box className="DocumentRoot" sx={{ height: "100%" }}>
      <SidebarContext>
        <Stack useFlexGap gap={2} sx={{ height: "100%" }}>
          <Box flexShrink={0}>
            <QueryRenderer<DocumentRoot_Query>
              environment={environment}
              query={query}
              render={({
                error,
                props,
              }: {
                error: Error | null;
                props: DocumentRoot_Query$data | null;
              }) => <DocumentHeader query={props?.project} />}
              variables={
                {
                  friendlyId: projectId,
                } as DocumentRoot_Query$variables
              }
            />
          </Box>
          <Outlet />
        </Stack>
      </SidebarContext>
    </Box>
  );
}
