/**
 * @generated SignedSource<<89118b9cc88b3e62cdc387374ce1666e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnnotationReply_Query$data = {
  readonly createdBy: {
    readonly name: string;
    readonly picture: string | null | undefined;
  };
  readonly friendlyId: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AnnotationContent_Query">;
  readonly " $fragmentType": "AnnotationReply_Query";
};
export type AnnotationReply_Query$key = {
  readonly " $data"?: AnnotationReply_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnnotationReply_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnnotationReply_Query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AnnotationContent_Query"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Annotation",
  "abstractKey": null
};

(node as any).hash = "8184059b8b5b31646517676d0d7a1e8e";

export default node;
