import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useUpdateVersion_Mutation$variables } from "./__generated__/useUpdateVersion_Mutation.graphql";

export const useUpdateVersion = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useUpdateVersion_Mutation($id: ID!, $content: String) {
      updateVersion(
        input: { id: $id, content: $content }
      ) {
        version {
          id
          content
          html
          lastActivityOn
        }
      }
    }
  `);

  const func = (
    variables: useUpdateVersion_Mutation$variables,
    onCompleted?: (response: {}) => void
  ) => {
    mutation({
      variables,
      onCompleted,
    });
  };

  return [func, inFlight] as [
    (
      variables: useUpdateVersion_Mutation$variables,
      onCompleted?: (response: {}) => void
    ) => void,
    boolean
  ];
};
