import { ReactNode, createContext } from "react";
import env from "./environment";
import { useAuth0 } from "@auth0/auth0-react";

export type apiContextType = {
  get<T>(path: string): Promise<T>;
  post<T>(path: string, body?: object): Promise<T>;
  put<T>(path: string, body?: object): Promise<T>;
  del(path: string): Promise<void>;
};

const notImplemented = () => {
  throw new Error("Not Implemented");
};

export const apiContext = createContext<apiContextType>({
  get: notImplemented,
  post: notImplemented,
  put: notImplemented,
  del: notImplemented,
});

export default function ApiContext({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();

  const accessTokenArgs = {
    authorizationParams: {
      audience: env.authentication.audience,
      scope: "openid",
    },
  };

  const get = async <T,>(path: string) => {
    const jwt = await getAccessTokenSilently(accessTokenArgs);

    const response = await fetch(`${env.apiRoot}/${path}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    if (response.headers.get("Content-Type")?.startsWith("text/plain")) {
      return response.text() as T;
    }

    if (response.headers.get("Content-Type")?.startsWith("application/zip")) {
      return response.blob() as T;
    }

    return (await response.json()) as T;
  };

  const post = async <T,>(path: string, body: object) => {
    const jwt = await getAccessTokenSilently(accessTokenArgs);

    const headers: HeadersInit = {
      Authorization: `Bearer ${jwt}`,
    };

    if (!(body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    const response = await fetch(`${env.apiRoot}/${path}`, {
      method: "POST",
      headers,
      body: body instanceof FormData ? body : JSON.stringify(body),
    });

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    return (await response.json()) as T;
  };

  const put = async <T,>(path: string, body: object) => {
    const jwt = await getAccessTokenSilently(accessTokenArgs);

    const headers: HeadersInit = {
      Authorization: `Bearer ${jwt}`,
    };

    if (!(body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    const response = await fetch(`${env.apiRoot}/${path}`, {
      method: "PUT",
      headers,
      body: body instanceof FormData ? body : JSON.stringify(body),
    });

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    return (await response.json()) as T;
  };

  const del = async (path: string) => {
    const jwt = await getAccessTokenSilently(accessTokenArgs);

    const response = await fetch(`${env.apiRoot}/${path}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }
  };

  return (
    <apiContext.Provider value={{ get, post, put, del }}>
      {children}
    </apiContext.Provider>
  );
}
