/**
 * @generated SignedSource<<55791677c5760c0356f44cbdd52e2405>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmbeddedResource_Query$data = {
  readonly externalLink: string | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "EmbeddedResource_Query";
};
export type EmbeddedResource_Query$key = {
  readonly " $data"?: EmbeddedResource_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmbeddedResource_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmbeddedResource_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalLink",
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "8bcaf2c48d37094435dbed1708d8bd87";

export default node;
