/**
 * @generated SignedSource<<2669b284d6923711a372cfedb017c45d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useRestoreProject_Mutation$variables = {
  id: string;
};
export type useRestoreProject_Mutation$data = {
  readonly restoreProject: {
    readonly project: {
      readonly archived: boolean;
      readonly id: string;
      readonly lastActivityOn: any;
    };
  };
};
export type useRestoreProject_Mutation = {
  response: useRestoreProject_Mutation$data;
  variables: useRestoreProject_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateProjectPayload",
    "kind": "LinkedField",
    "name": "restoreProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastActivityOn",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRestoreProject_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRestoreProject_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e9d9ed21b572c561ee37e9b50e1e1e49",
    "id": null,
    "metadata": {},
    "name": "useRestoreProject_Mutation",
    "operationKind": "mutation",
    "text": "mutation useRestoreProject_Mutation(\n  $id: ID!\n) {\n  restoreProject(input: {id: $id}) {\n    project {\n      id\n      archived\n      lastActivityOn\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1bede4dd97031dcb0de25e374af08e5";

export default node;
