import { Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { ReactNode } from "react";

export default function Header({ header, action }: { header: string | ReactNode; action?: ReactNode }) {
  return (
    <Grid container xs={12} paddingBottom={2} flexShrink={0}>
      <Grid xs={8}>
        <Typography variant="h3" component="h2" align="left">
          {header}
        </Typography>
      </Grid>
      {action && (
        <Grid xs={4} alignSelf="center" textAlign="right">
          {action}
        </Grid>
      )}
    </Grid>
  );
}