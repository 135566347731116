import { ReactNode } from "react";
import env from "./environment";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Environment,
  Network,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from "relay-runtime";
import { RelayEnvironmentProvider } from "react-relay";

export type graphQLContextType = {
  environment: Environment;
};

export default function GraphQLContext({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();

  const accessTokenArgs = {
    authorizationParams: {
      audience: env.authentication.audience,
      scope: "openid",
    },
  };

  async function fetchQuery(
    operation: RequestParameters,
    variables: Variables
  ) {
    const jwt = await getAccessTokenSilently(accessTokenArgs);

    const response = await fetch(env.graphQLEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    });

    return response.json();
  }

  const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
  });

  return (
    <RelayEnvironmentProvider environment={environment}>
      {children}
    </RelayEnvironmentProvider>
  );
}
