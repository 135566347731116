import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

export const useDeleteVersion = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useDeleteVersion_Mutation($id: ID!) {
      deleteVersion(input: { id: $id }) {
        version {
          id @deleteRecord
        }
      }
    }
  `);

  const func = (id: string, onCompleted?: (response: {}) => void) => {
    mutation({
      variables: {
        id,
      },
      onCompleted,
    });
  };

  return [func, inFlight] as [
    (id: string, onCompleted?: (response: {}) => void) => void,
    boolean
  ];
};
