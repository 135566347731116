import {
  useTheme,
  useMediaQuery,
  Box,
  Avatar,
  Typography,
  Chip,
  IconButton,
} from "@mui/material";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  UserRow_Query$key,
  UserRow_Query$data,
} from "./__generated__/UserRow_Query.graphql";
import { Requires } from "../UserContext";

interface Props {
  query: UserRow_Query$key;
  open: boolean;
  openMenu: (
    event: React.MouseEvent<HTMLButtonElement>,
    user: Omit<UserRow_Query$data, " $fragmentType">
  ) => void;
}
export default function UserRow({ query, open, openMenu }: Props) {
  const data = useFragment(
    graphql`
      fragment UserRow_Query on User {
        id
        name
        email
        picture
        role
      }
    `,
    query
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Box>
        <Avatar alt={data.name} src={data.picture ?? undefined} />
      </Box>
      <Box flexGrow={1}>
        <Typography>{data.name}</Typography>
        {!matches && (
          <Typography variant="body2" color="text.secondary">
            {data.email}
          </Typography>
        )}
      </Box>
      {matches && (
        <Box>
          <Typography variant="body2" color="text.secondary">
            {data.email}
          </Typography>
        </Box>
      )}
      <Box>
        <Chip label={data.role} />
      </Box>
      <Requires requires="ADMIN">
        <Box marginRight={-2}>
          <IconButton
            onClick={(ev) => openMenu(ev, data)}
            aria-label="actions"
            aria-controls={open ? "user-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Requires>
    </>
  );
}
