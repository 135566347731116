import Sidebar from "./Sidebar";
import CommentIcon from "@mui/icons-material/Comment";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import InfoIcon from "@mui/icons-material/Info";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useContext } from "react";
import { sidebarContext } from "../Contexts/SidebarContext";

export default function RightSidebar({
  displayButtons,
}: {
  displayButtons: ("Annotations" | "Versions" | "Format")[];
}) {
  const { rightSidebarPane, setRightSidebarPane } = useContext(sidebarContext);

  return (
    <Sidebar>
      <ToggleButtonGroup
        color="primary"
        orientation="vertical"
        value={rightSidebarPane}
        exclusive
        onChange={(
          ev: React.MouseEvent<HTMLElement>,
          newView: "Annotations" | "Versions" | "Format"
        ) => setRightSidebarPane(newView ?? "None")}
        sx={{
          "& .MuiToggleButtonGroup-grouped": {
            margin: 0,
            border: 0,
            borderRadius: 0,
            padding: 0,
            "&.Mui-selected": {
              borderLeft: "2px solid",
            },
          },
        }}
      >
        {displayButtons.includes("Annotations") && (
          <ToggleButton value="Annotations" aria-label="Annotations">
            <Tooltip
              placement="left"
              title="Annotations"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: ".9rem",
                    color: "text.primary",
                    backgroundColor: "background.paper",
                    border: "1px solid",
                  },
                },
              }}
              arrow
            >
              {<CommentIcon sx={{ padding: 2 }} />}
            </Tooltip>
          </ToggleButton>
        )}
        {displayButtons.includes("Versions") && (
          <ToggleButton value="Versions" aria-label="Versions">
            <Tooltip
              placement="left"
              title="Versions"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: ".9rem",
                    color: "text.primary",
                    backgroundColor: "background.paper",
                    border: "1px solid",
                  },
                },
              }}
              arrow
            >
              {<FormatListNumberedIcon sx={{ padding: 2 }} />}
            </Tooltip>
          </ToggleButton>
        )}
        {displayButtons.includes("Format") && (
          <ToggleButton value="Format" aria-label="Format">
            <Tooltip
              placement="left"
              title="Format"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: ".9rem",
                    color: "text.primary",
                    backgroundColor: "background.paper",
                    border: "1px solid",
                  },
                },
              }}
              arrow
            >
              {<InfoIcon sx={{ padding: 2 }} />}
            </Tooltip>
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Sidebar>
  );
}
