import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import Header from "../Header";
import { FormEvent, useContext, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUpdateUser } from "./hooks/useUpdateUser";
import { apiContext } from "../ApiContext";
import { QueryRenderer, useRelayEnvironment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  UserProfileRoot_Query,
  UserProfileRoot_Query$data,
} from "./__generated__/UserProfileRoot_Query.graphql";
import { useChangePassword } from "./hooks/useChangePassword";

export default function UserProfileRoot() {
  const environment = useRelayEnvironment();

  const query = graphql`
    query UserProfileRoot_Query {
      self {
        id
        name
        email
        role
      }
    }
  `;

  const { user } = useAuth0();
  const { post } = useContext(apiContext);
  const [updateUser] = useUpdateUser();
  const [changePassword] = useChangePassword();
  const fileInput = useRef<HTMLInputElement | null>(null);

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);

  const [name, setName] = useState({
    value: user?.name ?? "",
    error: false,
    errorMessage: "",
  });

  const [email, setEmail] = useState({
    value: user?.email ?? "",
    error: false,
    errorMessage: "",
  });

  const [pictureLink, setPictureLink] = useState(user?.picture);

  const handleEmailChange = (value: string) => {
    if (!value?.length) {
      setEmail({ value, error: true, errorMessage: "Email is required" });
    } else if (
      value.indexOf("@") === -1 ||
      value.lastIndexOf(".") < value.indexOf("@")
    ) {
      setEmail({ value, error: true, errorMessage: "Invalid email" });
    } else {
      setEmail({ value, error: false, errorMessage: "" });
    }
  };

  const handleNameChange = (value: string) => {
    if (!value?.length) {
      setName({ value, error: true, errorMessage: "Name is required" });
    } else {
      setName({ value, error: false, errorMessage: "" });
    }
  };

  const handlePictureChange = async (file?: File | null) => {
    if (!file || !user?.sub) {
      return;
    }

    const formData = new FormData();
    formData.append("input", file);
    const response = await post<string>(
      `upload/${user.sub}/profile-pic`,
      formData
    );

    setPictureLink(response);
  };

  const onSubmit = (id?: string | null) => async (ev: FormEvent) => {
    ev.preventDefault();

    if (!id) {
      return;
    }

    updateUser({
      id,
      email: email.value,
      name: name.value,
      picture: pictureLink,
    });
  };

  return (
    <QueryRenderer<UserProfileRoot_Query>
      environment={environment}
      query={query}
      render={({
        error,
        props,
      }: {
        error: Error | null;
        props: UserProfileRoot_Query$data | null;
      }) => (
        <Box className="UserProfileRoot" marginX={5} sx={{ height: "100%" }}>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={changePasswordDialogOpen}
            onClose={() => setChangePasswordDialogOpen(false)}
            aria-labelledby="change-password-dialog-title"
            aria-describedby="change-password-dialog-description"
          >
            <DialogTitle id="change-password-dialog-title">
              Change Password Email Sent
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="change-password-dialog-description"
                overflow="auto"
              >
                A change password email was sent to {user?.email}. Follow the
                instructions in the email to finish changing your password.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setChangePasswordDialogOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Stack sx={{ height: "100%" }}>
            <Header header="Profile" />
            <Box marginBottom={2} sx={{ overflow: "auto" }}>
              <form onSubmit={onSubmit(props?.self.id)}>
                <Stack direction="row" spacing={2}>
                  <Avatar
                    alt={user?.name ?? ""}
                    src={pictureLink}
                    sx={{ width: "150px", height: "150px" }}
                    onClick={() => fileInput.current?.click()}
                  />
                  <input
                    ref={fileInput}
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(ev) => handlePictureChange(ev.target.files?.[0])}
                  />
                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      required
                      type="email"
                      label="Email"
                      variant="standard"
                      value={email.value}
                      error={email.error}
                      helperText={email.errorMessage}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleEmailChange(event.target.value);
                      }}
                    />
                    <TextField
                      fullWidth
                      required
                      label="Name"
                      variant="standard"
                      value={name.value}
                      error={name.error}
                      helperText={name.errorMessage}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleNameChange(event.target.value);
                      }}
                    />
                    <Stack direction="row" gap={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ width: "fit-content" }}
                        disabled={name.error || email.error}
                      >
                        Save
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        sx={{ width: "fit-content" }}
                        onClick={() => {
                          if (props?.self.id) {
                            changePassword(props.self.id);
                            setChangePasswordDialogOpen(true);
                          }
                        }}
                      >
                        Change Password
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Box>
      )}
      variables={{}}
    />
  );
}
