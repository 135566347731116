import { Divider, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DocumentList from "../sidebar-panels/DocumentList/DocumentList";
import LeftSidebar from "../Sidebars/LeftSidebar";
import RightSidebar from "../Sidebars/RightSidebar";
import DocumentEditor from "../DocumentEditor/DocumentEditor";
import Annotations from "../sidebar-panels/Annotations/Annotations";
import FormatPane from "../sidebar-panels/Format/FormatPane";
import { QueryRenderer, useRelayEnvironment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  EditDocumentPage_Query,
  EditDocumentPage_Query$data,
  EditDocumentPage_Query$variables,
} from "./__generated__/EditDocumentPage_Query.graphql";
import { isProjectReadOnly } from "../utilities/utilities";
import Search from "../sidebar-panels/Search/Search";

export default function EditDocumentPage() {
  const environment = useRelayEnvironment();

  const query = graphql`
    query EditDocumentPage_Query(
      $friendlyProjectId: String!
      $friendlyDocumentId: String!
      $friendlyVersionId: String!
    ) {
      project(friendlyId: $friendlyProjectId) {
        archived
        ...DocumentList_Query
        document(friendlyId: $friendlyDocumentId) {
          id
          type
          version(friendlyId: $friendlyVersionId) {
            id
            friendlyId
            ...DocumentEditor_Query
            annotations {
              ...Annotations_Query
            }
          }
          versions {
            edges {
              node {
                id
                draft
                lastActivityOn
              }
            }
          }
        }
      }
    }
  `;

  const navigate = useNavigate();
  const { projectId, documentId, versionId } = useParams();

  return (
    <QueryRenderer<EditDocumentPage_Query>
      environment={environment}
      query={query}
      render={({
        error,
        props,
      }: {
        error: Error | null;
        props: EditDocumentPage_Query$data | null;
      }) => (
        <Stack
          className="EditDocumentView"
          useFlexGap
          flexGrow={1}
          direction="row"
          gap={2}
          sx={{ overflow: "hidden" }}
        >
          <LeftSidebar displayButtons={["Documents", "Search"]} />
          <DocumentList
            query={props?.project}
            activeDocumentId={props?.project.document?.id}
            readOnly={isProjectReadOnly(props?.project)}
          />
          <Search />
          <Divider flexItem variant="middle" orientation="vertical" />
          <Stack flexGrow={1}>
            {props?.project.document?.version && (
              <DocumentEditor
                query={props.project.document.version}
                type={
                  props.project.document.type === "MARKDOWN"
                    ? "MARKDOWN"
                    : "MERMAID"
                }
                setView={() => navigate("../")}
              />
            )}
          </Stack>
          <Divider flexItem variant="middle" orientation="vertical" />
          {(props?.project.document?.type === "MARKDOWN" ||
            props?.project.document?.type === "MERMAID") && (
            <Annotations
              query={props.project.document.version?.annotations}
              readOnly={isProjectReadOnly(props?.project)}
              activeVersionId={props.project.document.version?.id}
            />
          )}
          <FormatPane />
          <RightSidebar displayButtons={["Annotations", "Format"]} />
        </Stack>
      )}
      variables={
        {
          friendlyProjectId: projectId,
          friendlyDocumentId: documentId,
          friendlyVersionId: versionId,
        } as EditDocumentPage_Query$variables
      }
    />
  );
}
