import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useCreateDocument_Mutation$variables } from "./__generated__/useCreateDocument_Mutation.graphql";

export const useCreateDocument = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useCreateDocument_Mutation(
      $projectId: ID!
      $name: String!
      $type: DocumentType!
      $externalLink: String
      $connections: [ID!]!
    ) {
      createDocument(
        input: {
          projectId: $projectId
          name: $name
          type: $type
          externalLink: $externalLink
        }
      ) {
        document
          @appendNode(
            connections: $connections
            edgeTypeName: "DocumentsEdge"
          ) {
          id
          friendlyId
          name
          projectId
          type
          externalLink
          favorite
          lastActivityOn
        }
      }
    }
  `);

  const func = (variables: useCreateDocument_Mutation$variables) => {
    mutation({
      variables,
    });
  };

  return [func, inFlight] as [
    (variables: useCreateDocument_Mutation$variables) => void,
    boolean
  ];
};
