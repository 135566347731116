import { Box, Button, ButtonGroup, Menu } from "@mui/material";
import { useState } from "react";
import AnnotationDialogForm from "../sidebar-panels/Annotations/AnnotationDialogForm";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { MarkdownViewer_Query$key } from "./__generated__/MarkdownViewer_Query.graphql";
import { useCreateAnnotation_Mutation$variables } from "../hooks/annotation/__generated__/useCreateAnnotation_Mutation.graphql";
import { Requires } from "../../UserContext";
import MarkdownRender from "./MarkdownRender";

interface Props {
  query?: MarkdownViewer_Query$key | null;
  readOnly: boolean;
  showEdit: boolean;
  setEdit: VoidFunction;
  createAnnotation: (
    variables: Omit<useCreateAnnotation_Mutation$variables, "connections">
  ) => void;
}
export default function MarkdownViewer({
  query,
  readOnly,
  showEdit,
  setEdit,
  createAnnotation,
}: Props) {
  const data = useFragment(
    graphql`
      fragment MarkdownViewer_Query on ContentVersion {
        id
        html
      }
    `,
    query
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [highlightedText, setHighlightedText] = useState<string>();

  const [newAnnotationDialogOpen, setNewAnnotationDialogOpen] = useState(false);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (readOnly) {
      // when I build integrations with other content
      // this flag will move to just disable the annotation button
      // with only one button, though, it lives here
      return;
    }

    const highlight = window.getSelection()?.toString();
    if (!highlight) {
      return;
    }

    setHighlightedText(highlight);
    setAnchorEl(event.target as HTMLElement);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setHighlightedText(undefined);
  };

  const createAnnotationAction = () => {
    setNewAnnotationDialogOpen(true);
  };

  const saveNewAnnotation = (content: string) => {
    if (data?.id) {
      createAnnotation({
        versionId: data.id,
        selectionText: highlightedText,
        content,
      });
    }
    setNewAnnotationDialogOpen(false);
    closeMenu();
  };

  const cancelNewAnnotation = () => {
    setNewAnnotationDialogOpen(false);
    closeMenu();
  };

  return (
    <Box
      className="MarkdownViewer"
      flexGrow={1}
      sx={{ overflow: "auto", paddingRight: 1 }}
    >
      <Menu
        id="highlight-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        MenuListProps={{
          "aria-label": "highlight actions menu",
          disablePadding: true,
        }}
      >
        <ButtonGroup size="large" variant="text" aria-label="highlight actions">
          <Button onClick={createAnnotationAction}>Annotate</Button>
          {/* <Button>Two</Button>
          <Button>Three</Button> */}
        </ButtonGroup>
      </Menu>
      {showEdit && (
        <Requires requires="WRITE">
          <Button
            onClick={setEdit}
            variant="contained"
            sx={{
              marginRight: 2,
              marginBottom: -5,
              float: "right",
              position: "sticky",
              top: 0,
            }}
          >
            Edit
          </Button>
        </Requires>
      )}
      <MarkdownRender onMouseUp={openMenu} html={data?.html} />
      <AnnotationDialogForm
        open={newAnnotationDialogOpen}
        onSave={saveNewAnnotation}
        onCancel={cancelNewAnnotation}
      />
    </Box>
  );
}
