import { Outlet, useNavigate } from "react-router-dom";
import ApiContext from "./ApiContext";
import ConfirmationDialogContext from "./ConfirmationDialogContext";
import SiteHeader from "./SiteHeader/SiteHeader";
import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import { AppState, Auth0Provider, User } from "@auth0/auth0-react";
import env from "./environment";
import mermaid from "mermaid";
import GraphQLContext from "./GraphQLContext";
import UserContext from "./UserContext";
import { themeOptions, acmeThemeOptions } from "./themes/site";

export default function App() {
  mermaid.initialize({ startOnLoad: false });

  const navigate = useNavigate();
  const onRedirect = (appState?: AppState, user?: User) => {
    const redirect =
      !appState?.returnTo || appState.returnTo === "/"
        ? "/projects"
        : appState.returnTo;
    navigate(redirect);
  };

  const getSubdomain = () => window.location.origin.split('//')[1].split(".")[0];

  const getThemeOptions = () => {
    const subdomain = getSubdomain();

    if (subdomain === "acme") {
      return acmeThemeOptions;
    }
    return themeOptions;
  };

  const getOrganization = () => {
    const subdomain = getSubdomain();

    if (subdomain === "acme") {
      return env.authentication.organization.acme;
    }
    return env.authentication.organization.local;
  }

  const theme = createTheme(getThemeOptions());

  return (
    <Auth0Provider
      useRefreshTokens
      useRefreshTokensFallback
      domain={env.authentication.domain}
      clientId={env.authentication.clientId}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirect}
      authorizationParams={{
        audience: env.authentication.audience,
        scope: env.authentication.scope,
        redirect_uri: window.location.origin,
        organization: getOrganization()
      }}
    >
      <ThemeProvider theme={theme}>
        <GraphQLContext>
          <ApiContext>
            <UserContext>
              <Stack
                className="App"
                sx={{ height: "100vh", overflow: "hidden" }}
              >
                <ConfirmationDialogContext>
                  <SiteHeader />
                  <Box flexGrow={1} sx={{ overflow: "hidden" }}>
                    <Outlet />
                  </Box>
                </ConfirmationDialogContext>
              </Stack>
            </UserContext>
          </ApiContext>
        </GraphQLContext>
      </ThemeProvider>
    </Auth0Provider>
  );
}
