/**
 * @generated SignedSource<<af9a07d92440b89897469227e1dcbf66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectCard_Query$data = {
  readonly archived: boolean;
  readonly description: string;
  readonly friendlyId: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "ProjectCard_Query";
};
export type ProjectCard_Query$key = {
  readonly " $data"?: ProjectCard_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectCard_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectCard_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "a7d6af6a848ec287386d4723e8d81a98";

export default node;
