import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  DocumentType,
  DocumentDialogForm_Query$key,
} from "./__generated__/DocumentDialogForm_Query.graphql";
import { isFigmaDocument, isPdfDocument } from "../../utilities/utilities";

interface Props {
  query?: DocumentDialogForm_Query$key | null;

  title: string;
  open: boolean;
  onCancel: VoidFunction;
  onSave: (document: {
    name: string;
    type: DocumentType;
    externalLink: string;
  }) => void;
}
export default function DocumentDialogForm({
  query,
  title,
  open,
  onCancel,
  onSave,
}: Props) {
  const data = useFragment(
    graphql`
      fragment DocumentDialogForm_Query on Document {
        id
        name
        type
        externalLink
      }
    `,
    query
  );

  const defaultTextFieldState = {
    value: "",
    error: false,
    errorMessage: "",
  };

  const documentTypes = [
    { name: "Markdown", value: "MARKDOWN" },
    { name: "Mermaid", value: "MERMAID" },
    { name: "Figma", value: "FIGMA" },
    { name: "Pdf", value: "PDF" },
    { name: "Link", value: "LINK" },
  ];

  const externalDocumentTypes = ["FIGMA", "PDF", "LINK"];

  const [name, setName] = useState(defaultTextFieldState);
  const [type, setType] = useState<DocumentType>("MARKDOWN");
  const [externalLink, setExternalLink] = useState(defaultTextFieldState);

  const handleNameChange = (value: string) => {
    if (!value?.length) {
      setName({ value, error: true, errorMessage: "Name is required" });
    } else if (value.length > 20) {
      setName({
        value,
        error: true,
        errorMessage: "Name is limited to 20 characters",
      });
    } else {
      setName({ value, error: false, errorMessage: "" });
    }
  };

  const handleTypeChange = ({
    value,
    externalLink,
  }: {
    value: DocumentType;
    externalLink: { value: string | undefined | null; error: boolean };
  }) => {
    if (!documentTypes.find((f) => f.value === value)) {
      return;
    }

    setType(value);

    if (externalDocumentTypes.indexOf(value) !== -1) {
      if (!externalLink?.value) {
        setExternalLink({
          value: "",
          error: true,
          errorMessage: "External link is required",
        });
      }
    } else if (externalLink.error) {
      setExternalLink(defaultTextFieldState);
    }
  };

  const handleExternalLinkChange = ({
    value,
    type,
  }: {
    value?: string | null;
    type: DocumentType;
  }) => {
    if (!value?.length && externalDocumentTypes.indexOf(type) !== -1) {
      setExternalLink({
        value: value ?? "",
        error: true,
        errorMessage: "External link is required",
      });
    } else if (
      type === "FIGMA" &&
      !isFigmaDocument({ type, externalLink: value })
    ) {
      setExternalLink({
        value: value!,
        error: true,
        errorMessage: "Provide a valid Figma link",
      });
    } else if (
      type === "PDF" &&
      !isPdfDocument({ type, externalLink: value })
    ) {
      setExternalLink({
        value: value!,
        error: true,
        errorMessage: "Provide a valid PDF link",
      });
    } else {
      setExternalLink({
        value: value ?? "",
        error: false,
        errorMessage: "",
      });
    }
  };

  const cancel = () => {
    onCancel();
    setName(defaultTextFieldState);
    setType("MARKDOWN");
    setExternalLink(defaultTextFieldState);
  };

  const save = () => {
    onSave({
      name: name.value,
      type,
      externalLink: externalLink.value,
    });
    setName(defaultTextFieldState);
    setType("MARKDOWN");
    setExternalLink(defaultTextFieldState);
  };

  useEffect(() => {
    if (data) {
      // assume if the data is coming from the db, it is already correct
      // we will re-validate as soon as anything changes anyway
      setName({
        value: data.name,
        error: false,
        errorMessage: "",
      });

      setType(data.type);

      setExternalLink({
        value: data.externalLink ?? "",
        error: false,
        errorMessage: "",
      });
    }
  }, [data]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={cancel}
      aria-labelledby="req-dialog-title"
    >
      <DialogTitle id="req-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            autoFocus
            fullWidth
            required
            label="Name"
            variant="standard"
            value={name.value}
            error={name.error}
            helperText={name.errorMessage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleNameChange(event.target.value);
            }}
          />
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="req-types-label">Type</InputLabel>
              <Select
                labelId="req-types-label"
                label="Type"
                value={type}
                onChange={(ev) =>
                  handleTypeChange({
                    value: ev.target.value as DocumentType,
                    externalLink,
                  })
                }
                disabled={!!data}
              >
                {documentTypes.map((m) => (
                  <MenuItem key={m.name} value={m.value}>
                    {m.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(type === "FIGMA" || type === "PDF" || type === "LINK") && (
              <TextField
                fullWidth
                required
                label="ExternalLink"
                variant="standard"
                value={externalLink.value}
                error={externalLink.error}
                helperText={externalLink.errorMessage}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleExternalLinkChange({ value: event.target.value, type });
                }}
              />
            )}
          </Stack>
          {type === "MARKDOWN" && (
            <Typography>
              Markdown-based documents.{" "}
              <Link
                href="https://en.wikipedia.org/wiki/Markdown"
                target="_blank"
                rel="noreferrer"
              >
                Learn more <LaunchIcon />
              </Link>
            </Typography>
          )}
          {type === "MERMAID" && (
            <Typography>
              Mermaid diagram documents.{" "}
              <Link
                href="http://mermaid.js.org/intro/syntax-reference.html"
                target="_blank"
                rel="noreferrer"
              >
                Learn more <LaunchIcon />
              </Link>
            </Typography>
          )}
          {type === "FIGMA" && (
            <Typography>
              Link to external Figma documents; it will be displayed embedded in
              this site.
            </Typography>
          )}
          {type === "PDF" && (
            <Typography>
              Link to external PDF document; it will be displayed embedded in
              this site.
            </Typography>
          )}
          {type === "LINK" && (
            <Typography>Link to external documents.</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={save} variant="contained" disabled={name.error}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
