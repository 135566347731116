import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

export const useUpdateAnnotation = () => {
  const [mutation, inFlight] = useMutation(graphql`
    mutation useUpdateAnnotation_Mutation($id: ID!, $content: String!) {
      updateAnnotation(input: { id: $id, content: $content }) {
        annotation {
          id
          content
          updatedOn
        }
      }
    }
  `);

  const func = (id: string, content: string) => {
    mutation({
      variables: {
        id,
        content
      },
    });
  };

  return [func, inFlight] as [(id: string, content: string) => void, boolean];
};
