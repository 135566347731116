export const isFigmaDocument = (document: {
  type: string;
  externalLink?: string | null;
}) => {
  return (
    !!document.externalLink &&
    document.type === "FIGMA" &&
    !!document.externalLink!.match(
      /https:\/\/([\w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/
    )
  );
};

export const isPdfDocument = (document: {
  type: string;
  externalLink?: string | null;
}) => {
  return (
    !!document.externalLink &&
    document.type === "PDF" &&
    !!document.externalLink!.match(/(.*)\.pdf?$/)
  );
};

export const isProjectReadOnly = (project?: { archived: boolean } | null) => {
  return project?.archived ?? true;
};
