import ProjectListHeader from "./ProjectHeader";
import ProjectList from "./ProjectList";
import { Box, Stack } from "@mui/material";
import { QueryRenderer, useRelayEnvironment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  ProjectRoot_Query,
  ProjectRoot_Query$data,
} from "./__generated__/ProjectRoot_Query.graphql";
import EditProjectModal from "./EditProjectModal";
import { useState } from "react";
import { useCreateProject } from "../hooks/useCreateProject";
import { useUpdateProject } from "../hooks/useUpdateProject";

export default function ProjectListRoot() {
  const environment = useRelayEnvironment();

  const query = graphql`
    query ProjectRoot_Query {
      projects {
        __id
        ...ProjectList_Query
      }
    }
  `;

  const [editProjectModalOpen, setEditProjectModalOpen] = useState(false);

  type projectType = Parameters<
    Parameters<typeof EditProjectModal>[0]["onSave"]
  >[0];
  const [projectWithEditModal, setProjectWithEditModal] =
    useState<Required<projectType>>();

  const [createProject, creating] = useCreateProject();
  const [editProject, editing] = useUpdateProject();

  const onSaveProject = (connectionId?: string) => (project: projectType) => {
    if (!projectWithEditModal) {
      createProject({
        name: project.name,
        description: project.description,
        connections: connectionId ? [connectionId] : [],
      });
    } else {
      editProject({
        id: projectWithEditModal.id,
        name: project.name,
        description: project.description,
      }, () => {
        setProjectWithEditModal(undefined);
      });
    }

    setEditProjectModalOpen(false);
  };

  const onCancel = () => {
    setProjectWithEditModal(undefined);
    setEditProjectModalOpen(false);
  };

  return (
    <Box className="ProjectRoot" marginX={5} sx={{ height: "100%" }}>
      <Stack sx={{ height: "100%" }}>
        <ProjectListHeader
          onCreateProject={() => setEditProjectModalOpen(true)}
          creating={creating}
        />
        <QueryRenderer<ProjectRoot_Query>
          environment={environment}
          query={query}
          render={({
            error,
            props,
          }: {
            error: Error | null;
            props: ProjectRoot_Query$data | null;
          }) => (
            <>
              <EditProjectModal
                project={projectWithEditModal}
                open={editProjectModalOpen}
                onCancel={onCancel}
                onSave={onSaveProject(props?.projects?.__id)}
              />
              <ProjectList
                onEdit={(project) => {
                  setProjectWithEditModal(project);
                  setEditProjectModalOpen(true);
                }}
                editing={(id) => projectWithEditModal?.id === id && editing}
                query={props?.projects}
              />
            </>
          )}
          variables={{}}
        />
      </Stack>
    </Box>
  );
}
