/**
 * @generated SignedSource<<c2d6668ad1a71feaa67cbd951a6a106a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentType = "FIGMA" | "LINK" | "MARKDOWN" | "MERMAID" | "PDF" | "%future added value";
export type EditDocumentPage_Query$variables = {
  friendlyDocumentId: string;
  friendlyProjectId: string;
  friendlyVersionId: string;
};
export type EditDocumentPage_Query$data = {
  readonly project: {
    readonly archived: boolean;
    readonly document: {
      readonly id: string;
      readonly type: DocumentType;
      readonly version: {
        readonly annotations: {
          readonly " $fragmentSpreads": FragmentRefs<"Annotations_Query">;
        } | null | undefined;
        readonly friendlyId: string;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"DocumentEditor_Query">;
      } | null | undefined;
      readonly versions: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly draft: boolean;
            readonly id: string;
            readonly lastActivityOn: any;
          };
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"DocumentList_Query">;
  };
};
export type EditDocumentPage_Query = {
  response: EditDocumentPage_Query$data;
  variables: EditDocumentPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "friendlyDocumentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "friendlyProjectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "friendlyVersionId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "friendlyId",
    "variableName": "friendlyProjectId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "friendlyId",
    "variableName": "friendlyDocumentId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "friendlyId",
    "variableName": "friendlyVersionId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastActivityOn",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "VersionsConnection",
  "kind": "LinkedField",
  "name": "versions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VersionsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentVersion",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "draft",
              "storageKey": null
            },
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "selectionText",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedOn",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "picture",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditDocumentPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentList_Query"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ContentVersion",
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DocumentEditor_Query"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnnotationsConnection",
                    "kind": "LinkedField",
                    "name": "annotations",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "Annotations_Query"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditDocumentPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentsConnection",
            "kind": "LinkedField",
            "name": "documents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "favorite",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v12/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "externalLink",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ContentVersion",
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "html",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnnotationsConnection",
                    "kind": "LinkedField",
                    "name": "annotations",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnnotationsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Annotation",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "replyTo",
                                "storageKey": null
                              },
                              (v15/*: any*/),
                              (v14/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "versionId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RepliesConnection",
                                "kind": "LinkedField",
                                "name": "replies",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RepliesEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Annotation",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v9/*: any*/),
                                          (v10/*: any*/),
                                          (v15/*: any*/),
                                          (v14/*: any*/),
                                          (v16/*: any*/),
                                          (v17/*: any*/),
                                          (v18/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b540ccec9d9fa30262868f36659a1f4",
    "id": null,
    "metadata": {},
    "name": "EditDocumentPage_Query",
    "operationKind": "query",
    "text": "query EditDocumentPage_Query(\n  $friendlyProjectId: String!\n  $friendlyDocumentId: String!\n  $friendlyVersionId: String!\n) {\n  project(friendlyId: $friendlyProjectId) {\n    archived\n    ...DocumentList_Query\n    document(friendlyId: $friendlyDocumentId) {\n      id\n      type\n      version(friendlyId: $friendlyVersionId) {\n        id\n        friendlyId\n        ...DocumentEditor_Query\n        annotations {\n          ...Annotations_Query\n        }\n      }\n      versions {\n        edges {\n          node {\n            id\n            draft\n            lastActivityOn\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment AnnotationContent_Query on Annotation {\n  id\n  selectionText\n  content\n  deleted\n  updatedOn\n  createdBy {\n    id\n    name\n  }\n}\n\nfragment AnnotationReply_Query on Annotation {\n  ...AnnotationContent_Query\n  id\n  friendlyId\n  createdBy {\n    name\n    picture\n    id\n  }\n}\n\nfragment Annotation_Query on Annotation {\n  ...AnnotationContent_Query\n  id\n  friendlyId\n  versionId\n  replyTo\n  createdBy {\n    name\n    picture\n    id\n  }\n  replies {\n    edges {\n      node {\n        id\n        friendlyId\n        lastActivityOn\n        ...AnnotationReply_Query\n      }\n    }\n  }\n}\n\nfragment Annotations_Query on AnnotationsConnection {\n  edges {\n    node {\n      id\n      lastActivityOn\n      replyTo\n      ...Annotation_Query\n    }\n  }\n}\n\nfragment DocumentDialogForm_Query on Document {\n  id\n  name\n  type\n  externalLink\n}\n\nfragment DocumentEditor_Query on ContentVersion {\n  id\n  content\n  html\n}\n\nfragment DocumentList_Query on Project {\n  id\n  friendlyId\n  documents {\n    edges {\n      node {\n        id\n        friendlyId\n        favorite\n        lastActivityOn\n        name\n        type\n        externalLink\n        ...DocumentDialogForm_Query\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85d7d6fc73be385361f8e3a38b467d13";

export default node;
